import {html, LitElement, PropertyValues, TemplateResult} from "lit";
import {customElement, query, state} from "lit/decorators.js";
import {resolve} from "../../../container";
import Styles from "./chapterLinks.lit.scss";
import {CHAPTER_LINK_EVENT, Chapters} from "./chapters";
import {Timeout} from "../../../common/timeout";
import {Dictionary} from "../dictionary";
import {GLOBAL} from "../../../common/globals";
import {PageScrollbar} from "../../../common/pageScrollbar";
import {ManagingResources} from "../../../common/lifetime";
import {IntersectionViewportObserver} from "../../../app/feedaggregator/contentsearch/intersectionViewportObserver";

const STATIC_ELEMENT_VISIBILITY_THRESHOLD = 120; // works fine for both cases with and without sticky header

@customElement("eop-chapter-links")
export class EopChapterLinks extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @query(".static-panel")
    private staticElement: Element;
    @query(".sticky-container")
    private stickyContainer: HTMLElement;
    @query("button")
    private button: HTMLElement;

    @state()
    private showButton: boolean;

    public constructor(
        private chapters: Chapters = resolve(Chapters),
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private timeout: Timeout = resolve(Timeout),
        private viewport: IntersectionViewportObserver = resolve(IntersectionViewportObserver)
    ) {
        super();
        this.showButton = false;
    }

    public render(): TemplateResult {
        const buttonLabel = Dictionary.of(this).translate("BUTTON_LABEL");
        return html`
            <nav class=static-panel>
                <ul class=items>
                    ${(this.renderChapters())}
                </ul>
            </nav>
            <div class=sticky-container ?active=${this.showButton}>
                <div class="backdrop" @click=${this.closePanel} data-tracking-label="close-sticky-chapter-panel-outside-click"></div>
                <div class=scroll-helper>
                    <div class=spacer>
                        <div class=grid>
                            <nav class="sticky-panel reverse-background">
                                <ul class=items>
                                    ${(this.renderHighlightableChapters())}
                                </ul>
                                <span class="close-button" @click=${this.closePanel} data-tracking-label="close-sticky-chapter-panel"></span>
                            </nav>
                        </div>
                        <button class="button secondary" type=button @click=${this.togglePanel}>${buttonLabel}</button>
                    </div>
                </div>
            </div>
        `;
    }

    private renderChapters(): TemplateResult[] {
        return this.chapters.getChapterData().map(chapter => html`
            <li>
                <eop-chapter-link .item=${chapter}></eop-chapter-link>
            </li>`
        );
    }

    private renderHighlightableChapters(): TemplateResult[] {
        return this.chapters.getChapterData().map(chapter => html`
            <li class=item>
                <eop-chapter-link class="highlightable" .item=${chapter} .highlight=${true}></eop-chapter-link>
            </li>`
        );
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.chapters.onChapterChange(() => this.requestUpdate(), this);
        this.addEventListener(CHAPTER_LINK_EVENT, (_: Event) => this.closePanel());
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        const observation = this.viewport.observe()
            .boundTo(this)
            .onChange(outOfViewport => this.updateButton(outOfViewport));
        this.timeout.delay(() => {
            // need to delay this even more to avoid firing before scroll is done when entering page with anchor in Chrome and Edge
            observation.main(this.staticElement, true, STATIC_ELEMENT_VISIBILITY_THRESHOLD);
        });

        const footerElement = GLOBAL.bodyElement().querySelector("footer")!;
        observation.footer(footerElement);
    }

    private updateButton(outOfViewport: boolean): void {
        this.showButton = outOfViewport;
        if (!this.showButton) {
            this.closePanel();
        }
    }

    private togglePanel(): void {
        this.classList.toggle("panel-open");
        this.updateScrollBehaviour();
    }

    private closePanel(): void {
        this.classList.remove("panel-open");
        this.updateScrollBehaviour();
    }

    private updateScrollBehaviour(): void {
        if (this.classList.contains("panel-open")
        ) {
            this.scrollFilterContainerToBottom();
            this.pageScrollbar.disablePageScrollability();
        } else {
            this.pageScrollbar.enablePageScrollability();
        }
    }

    private scrollFilterContainerToBottom(): void {
        this.stickyContainer.scrollTop = this.stickyContainer.scrollHeight;
    }

}
