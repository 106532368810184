import {Page} from "./page";
import {autoRegister, resolve} from "../container";
import {clamp} from "../bootstrap/common/numbers";

@autoRegister()
export class Visibility {
    public constructor(private page: Page = resolve(Page)) {
    }

    public isInsideViewport(element?: Element): boolean {
        if (!element) {
            return false;
        }
        const elementRectangle = element.getBoundingClientRect();

        return elementRectangle.top > 0
            && elementRectangle.bottom < this.page.viewportHeight();
    }

    public isVisibleInsideViewport(element: Element): boolean {
        return this.isInsideViewport(element)
            && element.isVisible();
    }

    public isInsideViewportAtPercentage(percentage: number, element: Element): boolean {
        const elementRectangle = element.getBoundingClientRect();
        const visibleTop = Math.max(0, elementRectangle.top);
        const visibleBottom = Math.min(elementRectangle.bottom, this.page.viewportHeight());

        const height = elementRectangle.bottom - elementRectangle.top;
        const heightInViewport = visibleBottom - visibleTop;

        return heightInViewport > 0
            && heightInViewport >= percentage * height;
    }

    public isCollapsed(element: Element): boolean {
        return element.parents()
            .filter(elem => getComputedStyle(elem).maxHeight === "0px")
            .isNotEmpty();
    }

    public isVisibleInsideViewportAtPercentage(percentage: number, element?: Element): boolean {
        if (!element) {
            return false;
        }
        return this.isInsideViewportAtPercentage(percentage, element)
            && element.isVisible();
    }

    public intersectsViewport(element?: Element): boolean {
        if (!element) {
            return false;
        }
        const elementRectangle = element.getBoundingClientRect();

        return (elementRectangle.top > 0 || elementRectangle.bottom > 0)
            && (elementRectangle.top < this.page.viewportHeight() || elementRectangle.bottom < this.page.viewportHeight());
    }

    public getViewportIntersectionHeight(element: Element, viewportWindow?: { top?: number; bottom?: number }): number {
        const viewportTop = viewportWindow?.top ?? 0;

        const viewportBottom = viewportWindow?.bottom ?? this.page.viewportHeight();
        const elementRectangle = element.getBoundingClientRect();
        const visibleTop = clamp(elementRectangle.top, viewportTop, viewportBottom);
        const visibleBottom = clamp(elementRectangle.bottom, viewportTop, viewportBottom);

        return visibleBottom - visibleTop;
    }

    public isOutsideViewport(element: Element): boolean {
        const elementRectangle = element.getBoundingClientRect();

        return !element.isVisible()
            || elementRectangle.top > this.page.viewportHeight()
            || elementRectangle.bottom < 0;
    }

    public sizeBelowViewport(element?: Element): number {
        if (!element?.isVisible()) {
            return 0;
        }

        const elementRectangle = element.getBoundingClientRect();
        const elementHeight = elementRectangle.bottom - elementRectangle.top;
        const distanceToViewportBottomEdge = elementRectangle.bottom - this.page.viewportHeight();
        const elementIsAboveBottomViewportEdge = distanceToViewportBottomEdge < 0;

        if (elementIsAboveBottomViewportEdge) {
            return 0;
        }
        return Math.min(elementHeight, distanceToViewportBottomEdge);
    }
}