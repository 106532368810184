import {PersistentTrigger, TriggerFactory} from "./triggers";

export class ImmediateTrigger extends PersistentTrigger {
    public constructor(...triggerCallbacks: (() => void)[]) {
        super(...triggerCallbacks);

        this.trigger();
    }
}

export class ImmediateTriggerFactory implements TriggerFactory {
    public createTrigger(element: Element, callback: () => void): ImmediateTrigger {
        return new ImmediateTrigger(callback);
    }
}