import {html, LitElement, TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./macherbusRankedProject.lit.scss";
import type {DirectiveResult} from "lit/directive.js";
import {templateContent} from "lit/directives/template-content.js";

@customElement("eop-macherbus-ranked-project")
export class EopMacherbusRankedProject extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "content"})
    public contentId: string;
    @property({attribute: "project-id"})
    private projectId: string;
    @property({state: true})
    private rank: number;
    @property({state: true})
    private votesCast: number;
    @property({attribute: "image-src"})
    private imageSrc: string;
    @property({attribute: "image-alt"})
    private imageAlt: string;

    private textualContent: DirectiveResult;

    public connectedCallback(): void {
        super.connectedCallback();

        const textualContentTemplate = this.resolveTemplateElement(this.contentId);
        if (textualContentTemplate !== null) {
            this.textualContent = templateContent(textualContentTemplate);
        }
    }

    //@formatter:off
    public render(): TemplateResult {
        return html`
            <div class="project-tile">
                <div class="tile-image">
                    <eop-responsive-image image-src=${this.imageSrc} image-alt=${this.imageAlt}></eop-responsive-image>
                    <div class="project-ranking">
                        <div class="rank">${this.rank}</div>
                        <div class="votes">${this.votesCast} <eop-msg key="VOTES_CAST_LABEL"></eop-msg></div>
                    </div>
                </div>
                <div class="tile-text reverse-background">${this.textualContent}</div>
            </div>
        `;
    }
    //@formatter:on

    public setRanking(rank: number, votesCast: number): void {
        this.rank = rank;
        this.votesCast = votesCast;
    }

    public compareTo(other: EopMacherbusRankedProject): number {
        return this.rank - other.rank;
    }

    public hasProjectId(projectId: string): boolean {
        return this.projectId === projectId;
    }

    public getProjectId(): string {
        return this.projectId;
    }
}