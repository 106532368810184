import {generify, isPrimitiveObject, isPrimitiveType, PrimitiveObject, PrimitiveType, PropertyMap} from "./utils/objects";
import {autoRegister} from "../container";
import {GLOBAL} from "./globals";

export interface LifecycleConstraint {
    until: number;
}

interface Property {
    base: PrimitiveObject;
    slot: string;
}

export type StorageValue = PrimitiveObject | PrimitiveType | undefined;

const STORAGE_PREFIX = "eop-";

export abstract class ClientStorage {

    protected constructor(private storage: Storage) {
    }

    protected store(key: string, value: PropertyMap): void {
        try {
            const object = generify(value);
            this.storage.setItem(STORAGE_PREFIX + key, JSON.stringify(object));
        } catch (e) {
            console.error("error during store", e);
        }
    }

    protected fetch(key: string): StorageValue {
        try {
            const content = this.get(key);
            if (!content) {
                return undefined;
            }
            return JSON.parse(content);
        } catch (e) {
            console.error("error during fetch", e);
            return undefined;
        }
    }

    protected storeInPath(key: string, path: string[], value: PropertyMap | PrimitiveType): void {
        try {
            const content = this.get(key) ?? "{}";
            let object = JSON.parse(content);

            if (path.isEmpty()) {
                object = generify(value);
            } else {
                const property = this.navigate(object, path);
                property.base[property.slot] = generify(value);
            }
            this.storage.setItem(STORAGE_PREFIX + key, JSON.stringify(object));
        } catch (e) {
            console.error("error during storeInPath", e);
        }
    }

    protected fetchFromPath(path: string[]): StorageValue {
        try {
            const key = path.shift()!;
            const content = this.get(key);
            if (!content) {
                return undefined;
            }
            const object = JSON.parse(content);
            const property = this.navigate(object, path);
            return property.base[property.slot];
        } catch (e) {
            console.error("error during fetchFromPath", e);
            return undefined;
        }
    }

    private navigate(object: PrimitiveObject, path: string[]): Property {
        const slot = path.pop()!;
        let base = object;
        for (const pathElement of path) {
            let next: PrimitiveType | PrimitiveObject | undefined = base[pathElement];
            if (!next || isPrimitiveType(next)) {
                next = {};
                base[pathElement] = next;
            }
            base = next;
        }
        return {base: base, slot: slot};
    }

    protected put(key: string, value: PrimitiveType): void {
        this.storage.setItem(STORAGE_PREFIX + key, JSON.stringify(value));
    }

    public get(key: string): string | null {
        return this.storage.getItem(STORAGE_PREFIX + key);
    }

    public remove(key: string): void {
        this.storage.removeItem(STORAGE_PREFIX + key);
    }

}

@autoRegister()
export class LocalStorage extends ClientStorage {
    public constructor() {
        super(GLOBAL.window().localStorage);
    }

    public save(key: string, value: PropertyMap, lifecycleAttributes: LifecycleConstraint): void {
        if (lifecycleAttributes?.until) {
            value._expiring = lifecycleAttributes.until;
        }
        super.store(key, value);
    }

    public saveInPath(key: string, path: string[], value: PropertyMap, lifecycleAttributes: LifecycleConstraint): void {
        if (lifecycleAttributes?.until) {
            value._expiring = lifecycleAttributes.until;
        }
        super.storeInPath(key, path, value);
    }

    public fetch<T>(key: string): T | undefined {
        const rawObject = super.fetch(key);

        return this.stripLifecycleData(rawObject) as T | undefined;
    }

    private stripLifecycleData<T extends StorageValue>(value: T): T {
        if (isPrimitiveObject(value)) {
            delete value._expiring;
            for (const key of Object.keys(value)) {
                value[key] = this.stripLifecycleData(value[key]);
            }
        }
        return value;
    }
}

@autoRegister()
export class SessionStorage extends ClientStorage {
    public constructor() {
        super(GLOBAL.window().sessionStorage);
    }

    public save(key: string, value: PropertyMap): void {
        super.store(key, value);
    }

    public saveInPath(key: string, path: string[], value: PropertyMap | PrimitiveType): void {
        super.storeInPath(key, path, value);
    }

    public put(key: string, value: PrimitiveType): void {
        super.put(key, value);
    }

    public fetch<T>(key: string): T | undefined {
        return super.fetch(key) as T | undefined;
    }

    public fetchFromPath<T>(path: string[]): T | undefined {
        return super.fetchFromPath(path.clone()) as T | undefined;
    }
}