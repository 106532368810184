import {intRangeClosed} from "../../../bootstrap/common/arrays";
import * as UUID from "../../../common/uuid";
import {GLOBAL} from "../../../common/globals";
import {resolve} from "../../../container";
import {html, LitElement, TemplateResult} from "lit";
import {customElement, state} from "lit/decorators.js";
import Styles from "./footnotes.lit.scss";
import {EventBus} from "../../../common/eventBus";
import type {FootnoteElement} from "./footnoteText";
import {textBetween} from "../../../common/utils/between";
import {truncateWordsAfter} from "../../../common/utils/truncate";
import {elementFrom} from "../../../common/utils/html";


export const FOOTNOTE_REFERENCE_CLICK_EVENT = "footnoteClicked";

export class Footnote {

    private static readonly MAX_LENGTH_BEFORE_TRUNCATE = 68;

    public readonly linkElements: HTMLElement[];
    public readonly id: string;
    public truncatedText: string;

    public constructor(
        public readonly text: string,
        public readonly number: number,
        private eventBus: EventBus = resolve(EventBus)
    ) {
        this.linkElements = [];
        this.id = UUID.newUUID();
        const element = elementFrom<HTMLElement>(`<span>${this.text}</span>`);
        truncateWordsAfter(element, Footnote.MAX_LENGTH_BEFORE_TRUNCATE);
        this.truncatedText = element.innerHTML;
    }

    public addLinkElement(numberElement: HTMLElement): void {
        numberElement.innerText = "" + this.number;
        numberElement.classList.add("footnote-loaded");
        numberElement.addEventListener("click", () => this.eventBus.dispatchEvent(FOOTNOTE_REFERENCE_CLICK_EVENT, {id: this.id}));
        this.linkElements.push(numberElement);
    }
}

export class FootnoteReferenceElements {

    public constructor(public readonly elements: HTMLElement[]) {
    }

    public collect(): Footnote[] {
        const footnotes: Footnote[] = [];

        for (const element of this.elements) {
            const footnoteText = element.querySelector(".footnote-text-hidden")!.innerHTML ?? "";
            const linkElement = element.querySelector<HTMLElement>(".footnote-reference")!;

            const existingFootnote = footnotes.findFirst(footnote => footnote.text === footnoteText);
            if (existingFootnote) {
                existingFootnote.addLinkElement(linkElement);
            } else {
                const newFootnote = new Footnote(footnoteText, footnotes.length + 1);
                newFootnote.addLinkElement(linkElement);
                footnotes.push(newFootnote);
            }
        }

        return footnotes;
    }

    public separateNeighboursBy(separator: HTMLElement): void {
        for (let i = 0; i < this.elements.length - 1; i++) {
            if (this.elements[i].nextElementSibling === this.elements[i + 1] && this.noTextBetween(this.elements[i], this.elements[i + 1])) {
                this.elements[i].after(separator.cloneNode(true));
            }
        }
    }

    private noTextBetween(element1: HTMLElement, element2: HTMLElement): boolean {
        return textBetween(element1, element2).trim() === "";
    }
}

interface CrawlerWaitingForTargetOutput {
    forTargetFootnoteOutput: (output: HTMLElement) => ReadyCrawler;
}

interface ReadyCrawler {
    findFootnoteReferences: () => FootnoteReferenceElements;
}

export class FootnoteCrawler implements CrawlerWaitingForTargetOutput, ReadyCrawler {
    private targetFootnoteOutput: HTMLElement;

    private constructor() {
    }

    public static newInstance(): CrawlerWaitingForTargetOutput {
        return new FootnoteCrawler();
    }

    public forTargetFootnoteOutput(output: HTMLElement): ReadyCrawler {
        this.targetFootnoteOutput = output;
        return this;
    }

    public findFootnoteReferences(): FootnoteReferenceElements {
        const elementsOfConcern = [...GLOBAL.bodyElement().querySelectorAll<HTMLElement>("eop-footnotes, .footnote")];

        const targetOutputIndex = elementsOfConcern.indexOf(this.targetFootnoteOutput);
        const previousOutputIndex = this.findLastFootnoteOutputIn(elementsOfConcern, targetOutputIndex - 1);

        const elements: HTMLElement[] = intRangeClosed(previousOutputIndex + 1, targetOutputIndex - 1).map(i => elementsOfConcern[i]);
        return new FootnoteReferenceElements(elements);
    }

    private findLastFootnoteOutputIn(elements: HTMLElement[], maxIndex: number): number {
        for (let i = maxIndex; i >= 0; i--) {
            if (elements[i] instanceof EopFootnotes) {
                return i;
            }
        }
        return -1;
    }
}

@customElement("eop-footnotes")
export class EopFootnotes extends LitElement {

    public static readonly styles = Styles;

    @state()
    private footnotes: Footnote[];

    public connectedCallback(): void {
        super.connectedCallback();

        const footnoteReferenceElements = FootnoteCrawler.newInstance()
            .forTargetFootnoteOutput(this)
            .findFootnoteReferences();
        footnoteReferenceElements.separateNeighboursBy(elementFrom("<sup>,</sup>"));
        this.footnotes = footnoteReferenceElements
            .collect();
    }

    public render(): TemplateResult {
        return html`
            <ul class="footnotes" data-eventelement="footnotes">
                ${(this.renderFootnotes())}
            </ul>
        `;
    }

    private renderFootnotes(): TemplateResult[] {
        return this.footnotes.map((footnote, index) => html`
            <eop-footnote-text
                    id=${footnote.id}
                    data-tracking-label="toggle-footnote-${index + 1}"
                    .footnote=${this.mapFootnote(footnote, index + 1)}
            ></eop-footnote-text>
        `);
    }

    private mapFootnote(footnote: Footnote, index: number): FootnoteElement {
        return {
            text: footnote.text,
            number: index,
            truncatedText: footnote.truncatedText
        };
    }
}