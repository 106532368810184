import {autoRegister, resolve} from "../container";
import {LocalStorage} from "./clientStorage";
import {GLOBAL} from "./globals";
import type {GoogleTagManagerWindow} from "../tracking/googletagmanager/googleTagManager";
import {EventBus} from "./eventBus";


/*Changes here need to be synchronous with page.stg - initialColorScheme()*/

export const LIGHT_COLOR_SCHEME = "light-color-scheme";
export const DARK_COLOR_SCHEME = "dark-color-scheme";

type StoredColorScheme = {
    userColorScheme: string;
};

export type ColorSchemeData = {
    colorScheme: ColorScheme;
};

export enum ColorScheme {
    LIGHT = "Light", DARK = "Dark"
}

export const COLOR_SCHEME_CLASS_MAP = {
    [ColorScheme.LIGHT]: LIGHT_COLOR_SCHEME,
    [ColorScheme.DARK]: DARK_COLOR_SCHEME
};

const MILLIS_OF_90_DAYS = 1000 * 60 * 60 * 24 * 90;

export const COLOR_SCHEME_CHANGED_EVENT = "color-scheme-changed";

const colorSchemeKey = "colorScheme";

@autoRegister()
export class ColorSchemeService {

    private colorScheme: ColorScheme = ColorScheme.LIGHT;
    private window: GoogleTagManagerWindow;

    public constructor(
        private eventBus: EventBus = resolve(EventBus),
        private localStorage: LocalStorage = resolve(LocalStorage)
    ) {
        this.window = GLOBAL.window() as GoogleTagManagerWindow;
        this.window.dataLayer = this.window.dataLayer ?? [];

        const storedColorScheme = this.localStorage.fetch<StoredColorScheme>(colorSchemeKey);
        if (storedColorScheme) {
            this.colorScheme = storedColorScheme.userColorScheme === LIGHT_COLOR_SCHEME ? ColorScheme.LIGHT : ColorScheme.DARK;
        } else {
            this.colorScheme = this.getInitialColorScheme();
        }

        this.pushToDataLayer();
    }

    public getColorScheme(): ColorScheme {
        return this.colorScheme;
    }

    private getInitialColorScheme(): ColorScheme {
        const prefersDarkScheme = this.window.matchMedia("(prefers-color-scheme: dark)");
        if (prefersDarkScheme.matches) {
            return ColorScheme.DARK;
        } else {
            return ColorScheme.LIGHT;
        }
    }

    public toggleColorScheme(): void {
        this.applyColorScheme(this.colorScheme === ColorScheme.LIGHT ? ColorScheme.DARK : ColorScheme.LIGHT);
        this.pushToDataLayer();
        this.saveToStorage();
        this.eventBus.dispatchEvent<ColorSchemeData>(COLOR_SCHEME_CHANGED_EVENT, {colorScheme: this.colorScheme});
    }

    private applyColorScheme(colorScheme: ColorScheme): void {
        this.colorScheme = colorScheme;
        GLOBAL.bodyElement().classList.remove("preferred-color-scheme");
        GLOBAL.bodyElement().classList.remove(LIGHT_COLOR_SCHEME);
        GLOBAL.bodyElement().classList.remove(DARK_COLOR_SCHEME);
        GLOBAL.bodyElement().classList.add(COLOR_SCHEME_CLASS_MAP[this.colorScheme]);
    }

    private pushToDataLayer(): void {
        this.window.dataLayer.push({
            color_mode: this.colorScheme.toLowerCase()
        });
    }

    private saveToStorage(): void {
        const date90DaysInFuture = new Date().getTime() + MILLIS_OF_90_DAYS;
        this.localStorage.save(colorSchemeKey, {userColorScheme: COLOR_SCHEME_CLASS_MAP[this.colorScheme]}, {until: date90DaysInFuture});
    }
}