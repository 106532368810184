import {ModalDialogFactory} from "./modalDialog";
import {isNull} from "../../../common/utils/basics";
import {Resolution} from "../../../common/resolution";
import {BootstrapBreakpoint, bootstrapBreakpointByName} from "../../../common/resolutionConstants";
import type {Trigger} from "../../../common/trigger/triggers";
import {LocalStorage} from "../../../common/clientStorage";
import {Dictionary} from "../dictionary";
import {resolve} from "../../../container";
import {TriggerService} from "../../../common/trigger/triggerService";

const MILLIS_OF_90_DAYS = 1000 * 60 * 60 * 24 * 90;

interface ClientStorageStatus {
    enabled?: boolean;
}

export class EopModalDialog extends HTMLElement {

    private openTrigger: Trigger;

    public constructor(
        private triggerService: TriggerService = resolve(TriggerService),
        private localStorage: LocalStorage = resolve(LocalStorage),
        private modalDialog: ModalDialogFactory = resolve(ModalDialogFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const clientStorageKey = this.getAttribute("client-storage-key") ?? undefined;
        const closeOnOutsideClick = isNull(this.getAttribute("disable-close-on-outside-click"));
        const dialog = this.modalDialog.create()
            .withDictionary(Dictionary.of(this))
            .withContent(this.querySelector(".modal-dialog")!)
            .onClose(() => this.openTrigger.stop())
            .onOpen(() => this.disablePersistently(clientStorageKey))
            .closingOnOutsideClick(closeOnOutsideClick);

        const openDialog = (): void => {
            if (!this.isEnabledAccordingToStorage(clientStorageKey)) {
                return;
            }
            dialog.open();
        };

        const activation = new DialogActivation(this, this.getAttribute("active-from-breakpoint") ?? "xs");

        this.openTrigger = this.triggerService.forEvent(this.getAttribute("data-trigger") ?? undefined)
            .createTrigger(this, () => activation.ifActive(openDialog));
    }

    public disconnectedCallback(): void {
        this.openTrigger.stop();
    }

    private isEnabledAccordingToStorage(clientStorageKey: string | undefined): boolean {
        if (!clientStorageKey) {
            return true;
        }
        const storageContent = this.localStorage.fetch<ClientStorageStatus>(clientStorageKey);
        return storageContent?.enabled === undefined ? true : storageContent.enabled;
    }

    private disablePersistently(clientStorageKey: string | undefined): void {
        if (!clientStorageKey) {
            return;
        }
        const storageData: ClientStorageStatus = {enabled: false};
        const date90DaysInFuture = new Date().getTime() + MILLIS_OF_90_DAYS;
        this.localStorage.save(clientStorageKey, storageData, {until: date90DaysInFuture});
    }
}

class DialogActivation {
    private activationBreakpoint: BootstrapBreakpoint | null;
    private active: boolean;

    public constructor(
        private element: HTMLElement,
        activationBreakpointName: string,
        private resolution: Resolution = resolve(Resolution)
    ) {
        this.activationBreakpoint = bootstrapBreakpointByName(activationBreakpointName);
        this.setActive(!this.activationBreakpoint || this.resolution.downTo(this.activationBreakpoint));
        if (this.activationBreakpoint) {
            this.resolution.onBootstrapBreakpointChange(breakpoint => {
                this.setActive(breakpoint.isDownTo(this.activationBreakpoint!));
            });
        }
    }

    public ifActive(callback: () => void): void {
        if (this.active) {
            callback();
        }
    }

    private setActive(value: boolean): void {
        this.active = value;
        if (this.active) {
            this.element.style.cursor = "pointer";
        } else {
            this.element.style.cursor = "";
        }
    }
}


customElements.define("eop-modal-dialog", EopModalDialog);