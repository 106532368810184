import type {ElementViewTrackingData, InputTrackingData, InteractionTrackingData, VideoEventTrackingData, ViewTrackingData} from "../trackingData";
import type {TrackingListener} from "../listener";
import {Tracking} from "../tracking";
import {resolve} from "../../container";

export class DebugTrackingServiceListener implements TrackingListener {

    public interaction(data: InteractionTrackingData): void {
        console.log("debug tracking interaction:", data);
    }

    public elementView(data: ElementViewTrackingData): void {
        console.log("debug tracking elementView:", data);
    }

    public errorView(data: ViewTrackingData): void {
        console.log("debug tracking errorView:", data);
    }

    public view(data: ViewTrackingData): void {
        console.log("debug tracking view:", data);
    }

    public initialView(data: ViewTrackingData): void {
        console.log("debug tracking initial view:", data);
    }

    public input(data: InputTrackingData): void {
        console.log("debug tracking input:", data);
    }

    public videoEvent(data: VideoEventTrackingData): void {
        console.log("debug tracking video event:", data);
    }
}

export class EopDebugTracking extends HTMLElement {

    public constructor(private tracking: Tracking = resolve(Tracking)) {
        super();
    }

    public connectedCallback(): void {
        this.tracking.registerUnnamedListener(new DebugTrackingServiceListener());
    }
}

customElements.define("eop-debug-tracking", EopDebugTracking);