import {GLOBAL} from "../../../common/globals";
import {resolve} from "../../../container";
import {PageOverlayTrustPolicyProvider} from "./pageOverlay";
import {EventInstaller, prepareEvents} from "../../../common/utils/events";
import type {Lifetime} from "../../../common/lifetime";

export enum PageOverlayMessageType {
    CLIENT_LOADED_OR_CHANGED = "eop:page-overlay:client-loaded-or-changed",
    CLIENT_CLOSE_OVERLAY_REQUEST = "eop:page-overlay:client-close-overlay-request",
    CLIENT_OPEN_IN_OVERLAY_REQUEST = "eop:page-overlay:client-open-in-overlay-request",
    CLIENT_OPEN_WITHOUT_OVERLAY_REQUEST = "eop:page-overlay:client-open-without-overlay-request"
}

export type PageOverlayMessage<T = any> = {
    type: PageOverlayMessageType;
    data: T;
}

export type ClientLoadedOrChangedData = {
    href: string;
    title: string;
    numClientHistoryEntriesSinceStart: number;
}
export type ClientCloseOverlayRequestData = {
    trackingEvent: string | false;
}
export type ClientOpenInOverlayRequestData = {
    href: string;
}
export type ClientOpenWithoutOverlayRequestData = {
    href: string;
}

export class ClientPostings {
    private constructor(private targetOrigin: string) {
    }

    public static forHref(targetHref: string): ClientPostings {
        return new ClientPostings(new URL(targetHref).origin);
    }

    private post<T>(event: PageOverlayMessage<T>): void {
        GLOBAL.window().parent.postMessage(event, this.targetOrigin);
    }

    public closeOverlayRequestMessage(data: ClientCloseOverlayRequestData): void {
        this.post({
            type: PageOverlayMessageType.CLIENT_CLOSE_OVERLAY_REQUEST,
            data
        });
    }

    public loadedOrChangedMessage(data: ClientLoadedOrChangedData): void {
        this.post({
            type: PageOverlayMessageType.CLIENT_LOADED_OR_CHANGED,
            data
        });
    }

    public openInOverlayRequestMessage(data: ClientOpenInOverlayRequestData): void {
        this.post({
            type: PageOverlayMessageType.CLIENT_OPEN_IN_OVERLAY_REQUEST,
            data
        });
    }

    public requestOpenWithoutOverlayMessage(data: ClientOpenWithoutOverlayRequestData): void {
        this.post({
            type: PageOverlayMessageType.CLIENT_OPEN_WITHOUT_OVERLAY_REQUEST,
            data
        });
    }
}

export class ClientEvents {

    private events: EventInstaller<Window>;

    public constructor(lifetime: Lifetime) {
        this.events = prepareEvents(GLOBAL.window()).boundTo(lifetime);
    }

    public static for(lifetime: Lifetime): ClientEvents {
        return new ClientEvents(lifetime);
    }

    public loadedOrChanged(callback: (data: ClientLoadedOrChangedData) => void): this {
        this.onEventFromTrustedOrigin(PageOverlayMessageType.CLIENT_LOADED_OR_CHANGED, callback);
        return this;
    }

    public closeOverlayRequest(callback: (data: ClientCloseOverlayRequestData) => void): this {
        this.onEventFromTrustedOrigin(PageOverlayMessageType.CLIENT_CLOSE_OVERLAY_REQUEST, callback);
        return this;
    }

    public openInOverlayRequest(callback: (data: ClientOpenInOverlayRequestData) => void): this {
        this.onEventFromTrustedOrigin(PageOverlayMessageType.CLIENT_OPEN_IN_OVERLAY_REQUEST, callback);
        return this;
    }

    public openWithoutOverlayRequest(callback: (data: ClientOpenWithoutOverlayRequestData) => void): this {
        this.onEventFromTrustedOrigin(PageOverlayMessageType.CLIENT_OPEN_WITHOUT_OVERLAY_REQUEST, callback);
        return this;
    }

    private onEventFromTrustedOrigin<T>(eventType: PageOverlayMessageType, callback: (data: T) => void): void {
        this.events.on("message", (ev: MessageEvent<PageOverlayMessage<T>>) => {
            if (ev.data.type === eventType) {
                assertTrustedPageOverlayOrigin(ev);
                callback(ev.data.data);
            }
        });
    }
}

function assertTrustedPageOverlayOrigin(ev: MessageEvent<PageOverlayMessage>): void {
    if (!resolve(PageOverlayTrustPolicyProvider).get().isTrustedHref(ev.origin)) {
        throw Error(`Received untrusted page overlay event from ${ev.origin} of type ${ev.data.type}`);
    }
}