import {ScrollService} from "../../../common/scroll";
import {resolve} from "../../../container";
import {html, LitElement, TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import Styles from "./footnoteText.lit.scss";
import {Dictionary} from "../dictionary";
import {Resolution} from "../../../common/resolution";
import {BOOTSTRAP} from "../../../common/resolutionConstants";
import {classMap} from "lit/directives/class-map.js";
import {EventBus} from "../../../common/eventBus";
import {FOOTNOTE_REFERENCE_CLICK_EVENT} from "./footnotes";

import {ManagingResources} from "../../../common/lifetime";

const FOOTNOTE_SCROLL_MARGIN = 15;

export type FootnoteElement = {
    text: string;
    number: number;
    truncatedText: string;
};

@customElement("eop-footnote-text")
export class EopFootnoteText extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property()
    private footnote: FootnoteElement;
    @state()
    private isShortened: boolean;


    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private eventBus: EventBus = resolve(EventBus),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        super();
        this.isShortened = false;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.addEventListener("click", () => this.toggle());

        this.resolution.onBootstrapBreakpointChange(() => this.toggleExpansion(), this);
        this.toggleExpansion();

        this.eventBus.on(FOOTNOTE_REFERENCE_CLICK_EVENT, event => {
            if (event.data.id === this.id) {
                this.scrollService.scrollToElement(this, FOOTNOTE_SCROLL_MARGIN);
                this.expand();
            }
        }, this);
    }

    public render(): TemplateResult {
        const classes = {responsive: this.canBeTruncated()};
        return html`
            <li class=${classMap(classes)}>
                <span class="counter">${this.footnote.number}</span>
                ${this.renderFootnoteText()}
            </li>
        `;
    }

    private toggle(): void {
        this.isShortened = !this.isShortened;
    }

    private expand(): void {
        this.isShortened = false;
    }

    private canBeTruncated(): boolean {
        return this.footnote.text.length > this.footnote.truncatedText.length
            && this.resolution.upTo(BOOTSTRAP.XS);
    }

    private renderFootnoteText(): TemplateResult {
        const text = (this.canBeTruncated() && this.isShortened)
            ? this.footnote.truncatedText
            : this.footnote.text;
        return html`<span>${unsafeHTML(text)}${this.renderToggleButton()}</span>`;
    }

    private renderToggleButton(): TemplateResult | null {
        if (!this.canBeTruncated()) {
            return null;
        }

        const dictionary = Dictionary.of(this);
        if (this.isShortened) {
            return html`<span class="expand">${dictionary.translate("MSG_MORE")}</span>`;
        } else {
            return html`<span class="shrink">${dictionary.translate("MSG_LESS")}</span>`;
        }
    }

    private toggleExpansion(): void {
        this.isShortened = this.resolution.upTo(BOOTSTRAP.XS);
    }
}