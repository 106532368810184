import type {Constructor} from "./objects";

export function extendStatic<TYPE extends Object>(type: TYPE, functions: { [K in keyof TYPE]?: TYPE[K] }, typeLabel?: string): void {
    for (const key in functions) {
        if (type.hasOwnProperty(key)) {
            const labelForType: string | undefined = typeLabel ?? (type as any).name;
            const messagePart = labelForType ? (" on " + labelForType) : "";
            console.error(`Extension error: property ${key} already exists${messagePart}. Ignoring custom extension.`);
        } else {
            type[key] = functions[key]!;
        }
    }
}

export type Patched = {
    _original: any;
};

export function extendPrototype<TYPE extends Constructor<INSTANCE>, INSTANCE>(type: TYPE, functions: { [K in keyof INSTANCE]?: INSTANCE[K] }, typeName?: string): void {
    const labelForType: string | undefined = typeName ?? (type as any).name;
    for (const k in functions) {
        if (!!type.prototype[k]) {
            (type as any as Patched)._original ??= {};
            (type as any as Patched)._original[k] = type.prototype[k];
        }
    }
    extendStatic(type.prototype, functions, labelForType ? labelForType + ".prototype" : undefined);
}