import {customElement} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import Styles from "./navigationSearchLink.lit.scss";
import {Dictionary} from "../../../elements/dictionary";
import {NavModel} from "./navModel";
import {resolve} from "../../../../container";
import {ConditionalLitElement} from "../../../../common/elements";

@customElement("eop-navigation-search-link")
export class EopNavigationSearchLink extends ConditionalLitElement {

    public static readonly styles = Styles;

    private searchHref?: string;

    private dictionary: Dictionary;

    public constructor(private navModel: NavModel = resolve(NavModel)) {
        super();
        this.searchHref = this.navModel.getActiveSector()?.searchHref || this.navModel.getSearchHref();
    }

    protected renderTemplate(): TemplateResult {
        const searchLabel = this.dictionary.translate("MSG_HEADER_SEARCH_SUBMIT_BUTTON_LABEL");
        return html`<a href=${this.searchHref} title=${searchLabel} class="search-link" data-tracking-label="header-search-button">
            <span class="search-link-text">${searchLabel}</span>
        </a>`;
    }

    protected shouldRender(): boolean {
        return !!this.searchHref;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.dictionary = Dictionary.of(this);
    }
}