import {LocatedDates} from "../../common/utils/dates/locatedDates";
import {resolve} from "../../container";
import {isPresent} from "../../common/utils/basics";

export class EopDate extends HTMLElement {

    public constructor(locatedDates: LocatedDates = resolve(LocatedDates)) {
        super();
        const rawDate = this.parseRawDate();
        if (isPresent(rawDate)) {
            this.outerHTML = locatedDates.toLocalDateString(new Date(rawDate));
        }
    }

    private parseRawDate(): number | null {
        try {
            return this.textContent?.toInt() ?? null;
        } catch (e) {
            return null;
        }
    }
}

customElements.define("eop-date", EopDate);