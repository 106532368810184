import {ScrollService} from "../../common/scroll";
import {ViewportChangeListener} from "../../common/viewportChangeListener";
import {ViewportCallbackBuilder} from "../../common/viewportCallback";
import {resolve} from "../../container";
import {ManagingResources} from "../../common/lifetime";
import {Page} from "../../common/page";
import {customElement, property} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./backToTop.lit.scss";

@customElement("eop-back-to-top")
export class BackToTop extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    private readonly BACK_TO_TOP_THRESHOLD = 1400;

    @property({type: Boolean, reflect: true})
    public invisible: boolean = true;

    public constructor(
        private scrollService: ScrollService = resolve(ScrollService),
        private viewportChangeListener: ViewportChangeListener = resolve(ViewportChangeListener),
        private page: Page = resolve(Page)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        const viewportCallback = new ViewportCallbackBuilder()
            .withPage(this.page)
            .withPredicate(viewportState => viewportState.yScrollPosition > this.BACK_TO_TOP_THRESHOLD)
            .withCallback(shouldBeVisible => this.invisible = !shouldBeVisible)
            .build();

        this.viewportChangeListener.listenToConditionChange(viewportCallback, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="back-to-top" @click=${this.scrollToTop} data-tracking-label="back-to-top"></div>
        `;
    }

    public scrollToTop(): void {
        this.scrollService.scrollToTop();
    }
}