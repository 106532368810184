
export class ResolutionSource {
    public url: string;
    public width: number;
    public height: number;
    public focusX?: number;
    public focusY?: number;

    public constructor(url?: string, width?: number, height?: number, focusX?: number, focusY?: number) {
        this.url = url ?? "";
        this.width = width ?? 0;
        this.height = height ?? 0;
        this.focusX = focusX;
        this.focusY = focusY;
    }

    public parseWidth(token: string): this | undefined {
        if (!token.startsWith("w=")) {
            return undefined;
        }
        this.width = this.parseWithDefault0(token.slice(2));
        return this;
    }

    public parseHeight(token: string): this | undefined {
        if (!token.startsWith("h=")) {
            return undefined;
        }
        this.height = this.parseWithDefault0(token.slice(2));
        return this;
    }

    public parseFocusX(token: string): this | undefined {
        if (!token.startsWith("focusX=")) {
            return undefined;
        }
        this.focusX = this.parseOptional(token.slice(7));
        return this;
    }

    public parseFocusY(token: string): this | undefined {
        if (!token.startsWith("focusY=")) {
            return undefined;
        }
        this.focusY = this.parseOptional(token.slice(7));
        return this;
    }

    private parseWithDefault0(value: string): number {
        try {
            return value.toInt();
        } catch (e) {
            return 0;
        }
    }

    private parseOptional(value: string): number | undefined {
        try {
            return value.toInt();
        } catch (e) {
            return undefined;
        }
    }

    public parseOtherToken(token: string): this {
        if (this.url === "") {
            this.url += token;
        } else if (!this.url.includes("?")) {
            this.url += "?" + token;
        } else {
            this.url += "&" + token;
        }
        return this;
    }

    public validate(): this | undefined {
        if (this.url === "" || this.width === 0 || this.height === 0) {
            return undefined;
        }
        return this;
    }
}

export class ImageDimension {
    public width: number;
    public height: number;

    public constructor(width: number, height: number) {
        this.width = Math.ceil(width);
        this.height = Math.ceil(height);
    }

    public static from(element: HTMLElement): ImageDimension {
        const rect = ImageDimension.rescaledRect(element);

        return new ImageDimension(rect.width, rect.height);
    }

    private static rescaledRect(element: HTMLElement): DOMRect {
        const rect = element.getBoundingClientRect();

        return new DOMRect(rect.x, rect.y, Math.max(rect.width, element.offsetWidth ?? 0), Math.max(rect.height, element.offsetHeight ?? 0));
    }

    public limitTo(newWidth: number, newHeight: number): this {
        if (this.width <= newWidth && this.height <= newHeight) {
            return this;
        }

        const currentAspectRatio = this.width / this.height;
        const horizontalScale = newWidth / this.width;
        const verticalScale = newHeight / this.height;
        if (horizontalScale > verticalScale) {
            this.width = Math.ceil(newHeight * currentAspectRatio);
            this.height = newHeight;
        } else {
            this.width = newWidth;
            this.height = Math.ceil(newWidth / currentAspectRatio);
        }
        return this;
    }

    public applyAspectRatio(aspectRatio: number): this {
        if (this.height === 0) {
            return this;
        }

        const currentAspectRatio = this.width / this.height;

        if (currentAspectRatio > aspectRatio) {
            this.width = Math.ceil(aspectRatio * this.height);
        } else if (currentAspectRatio < aspectRatio) {
            this.height = Math.ceil(this.width / aspectRatio);
        }
        return this;
    }

    public isApplicableTo(source: ResolutionSource): boolean {
        return source.width >= this.width && source.height >= this.height;
    }

    public isValid(): boolean {
        return (this.width !== 0 && !isNaN(this.width))
            || (this.height !== 0 && !isNaN(this.height));
    }

    public matches(dimension: ImageDimension): boolean {
        return this.width === dimension.width && this.height === dimension.height;
    }
}