import {html, LitElement, TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import Styles from "./chapterLink.lit.scss";
import {ChapterData, chapterLinkEvent} from "./chapters";

@customElement("eop-chapter-link")
export class EopChapterLink extends LitElement {

    public static readonly styles = Styles;

    @state()
    private item: ChapterData;
    @property()
    private highlight: boolean;

    public render(): TemplateResult {
        return html`
            <div class="chapter-link">
                <a
                        class="link"
                        href=${this.item.href}
                        ?active=${this.highlight && this.item.active}
                        @click=${this.handleClick}>${this.item.name}
                </a>
            </div>
        `;
    }

    private handleClick(event: Event): void {
        event.preventDefault();
        this.dispatchEvent(chapterLinkEvent(this.item));
    }
}