import {customElement, property} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import Styles from "./navigationFlyoutContent.lit.scss";
import {
    isNavLevel2Item,
    type NavigationItem,
    type NavigationLevel2Item,
    type TeaserNavigationLevel2Item,
    TeaserNavigationLevel3Item,
    TeaserNavigationLevel4Item
} from "../common/navModel";
import {EopNavigationItemGroup} from "../common/navigationItemGroup";
import {isArray} from "../../../../bootstrap/common/arrays";

@customElement("eop-navigation-flyout-content")
export class EopNavigationFlyoutContent extends EopNavigationItemGroup {

    public static readonly styles = Styles;

    @property()
    public model: NavigationLevel2Item | TeaserNavigationLevel2Item;
    @property({reflect: true, type: Boolean})
    public selected: boolean = false;
    @property({reflect: true, type: Boolean})
    public closedInstantly: boolean = false;

    public connectedCallback(): void {
        super.connectedCallback();
        if (isNavLevel2Item(this.model)) {
            this.style.setProperty("--column-size", `${this.model.getSubItemsInColumns().length}`);
        }
    }

    public render(): TemplateResult {
        return html`
            <div class="desktop-flyout-scroll-container">
                <div class="desktop-flyout-content">
                    ${this.renderLevel2Link()}
                    ${this.renderLevel2SubLinks()}
                </div>
            </div>
        `;
    }

    public hasId(id: string): boolean {
        return this.model.id === id;
    }

    private renderLevel2Link(): TemplateResult {
        if (this.model.hasLink()) {
            return html`
                <a href=${this.model.href} target=${this.model.target} title=${this.model.label}
                   class="nav-item nav-link level-2">${this.model.label}</a>
            `;
        } else {
            return html`
                <div title=${this.model.label} class="nav-item level-2">${this.model.label}</div>
            `;
        }
    }

    private renderLevel2SubLinks(): TemplateResult | null {
        if (!this.model.hasSubItems()) {
            return null;
        }

        if (isNavLevel2Item(this.model)) {
            const subColumns = this.model.getSubItemsInColumns().map(column => this.renderSubLinksColumn(column));
            return html`
                <div class="nav-columns">${subColumns}</div>
            `;
        }

        const subRows = this.model.getSubItemsInRows().map(row => this.renderSubLinksRow(row));
        return html`
            <div class="nav-rows">
                ${subRows}
            </div>
        `;
    }

    private renderSubLinksColumn(items: NavigationItem[]): TemplateResult {
        return html`
            <div class="nav-column">${(this.renderSubLinks(items))}</div>
        `;
    }

    private renderSubLinks(items: NavigationItem[]): TemplateResult[] {
        return items.map(linkModel => html`
            <eop-navigation-level-3-block .model=${linkModel}></eop-navigation-level-3-block>
        `);
    }

    private renderSubLinksRow(navItem: TeaserNavigationLevel3Item | TeaserNavigationLevel3Item[]): TemplateResult {
        if (isArray(navItem)) {
            return html`
                <div class="nav-row">
                    <div class="nav-row-images">${(this.renderLevel3SubLinks(navItem))}</div>
                </div>
            `;
        }
        return html`
            <div class="nav-row">
                <div title=${navItem.label} class="nav-item level-3">${navItem.label}</div>
                <div class="nav-row-images">${(this.renderLevel4SubLinks(navItem))}</div>
            </div>
        `;
    }

    private renderLevel3SubLinks(navItem: TeaserNavigationLevel3Item[]): TemplateResult[] {
        return navItem.map(item => this.renderImageTeaser(item));
    }

    private renderLevel4SubLinks(navItem: TeaserNavigationLevel3Item): TemplateResult[] {
        return navItem.getSubItems().map(item => this.renderImageTeaser(item));
    }

    private renderImageTeaser(item: TeaserNavigationLevel3Item | TeaserNavigationLevel4Item): TemplateResult {
        return html`
            <eop-image-teaser
                    link=${item.href}
                    class="image-teaser-module image-teaser"
                    data-eventelement="imageteasermodule"
                    data-tracking-label=${item.label}
                    headline=${item.label}
                    headline-level=${item.headlineLevel ?? 4}
                    image-src=${item.imageSrc}
                    image-size=${item.imageSize}
                    image-background=${item.imageBackground}
                    image-shade=${item.imageShade}
                    video-src=${item.videoSrc}
                    hover-text=${item.imageAlt}
                    target=${item.target}
            ></eop-image-teaser>
        `;
    }
}