import {GLOBAL} from "../../common/globals";

export class EopArticleHeader extends HTMLElement {

    private readonly AVERAGE_WORDS_PER_MINUTE = 200;

    public connectedCallback(): void {
        const readingTimeElement = this.querySelector(".reading-time")!;
        readingTimeElement.textContent = this.determineReadingTime();
        this.querySelector<HTMLElement>(".article-header-bottom")!.style.visibility = "visible";
    }

    private determineReadingTime(): string {
        const text = GLOBAL.bodyElement().querySelector("main")?.innerText?.trim() ?? "";
        const numberOfWords = text.split(/\s+/).length;
        return Math.ceil(numberOfWords / this.AVERAGE_WORDS_PER_MINUTE) + " min";
    }
}

customElements.define("eop-article-header", EopArticleHeader);