import {HorizontalSwipeHandler} from "../../common/utils/events";
import {SlideModel} from "../../common/slideModel";
import {Animation, IndexedElement} from "../../common/animation";
import {resolve} from "../../container";
import {Interval} from "../../common/interval";
import {InteractionTracker} from "../../tracking/interactionTracking";
import {eopCustomEvent} from "../../common/eventBus";
import {customElement} from "lit/decorators.js";
import {UnLitElement} from "../../common/elements";


const CAROUSEL_LOADED_CLASS = "uni-carousel-loaded";
const SINGLE_ELEMENT_CLASS = "uni-carousel-single-element";
const ANIMATION_DURATION = 750;

export const UNI_CAROUSEL_ACTIVE_ELEMENT_CHANGE = "uni-carousel-active-element-change";

export class UniCarouselController {
    private elementsContainer: HTMLElement;
    private slideModel: SlideModel;

    public constructor(
        private element: Element,
        private slideElements: HTMLElement[],
        private interactionTracker: InteractionTracker = resolve(InteractionTracker)
    ) {
        if (slideElements.length < 2) {
            this.element.classList.add(SINGLE_ELEMENT_CLASS);
            return;
        }

        new HorizontalSwipeHandler([element])
            .onSwipeLeft((e) => {
                this.scrollRight();
                this.interactionTracker.trackUserInteraction(e, "swipe-left-uni-carousel");
            })
            .onSwipeRight((e) => {
                this.scrollLeft();
                this.interactionTracker.trackUserInteraction(e, "swipe-right-uni-carousel");
            })
            .activate();

        (this.element.querySelector(".prev-button") as HTMLElement)
            ?.addEventListener("click", () => this.scrollLeft());
        (this.element.querySelector(".next-button") as HTMLElement)
            ?.addEventListener("click", () => this.scrollRight());
        this.element.querySelectorAll(".carousel-indicator").forEach((htmlElement, index) => (htmlElement as HTMLElement)
            .addEventListener("click", () => this.scrollToElement(index)));

        this.element.classList.add(CAROUSEL_LOADED_CLASS);

        this.elementsContainer = this.element.querySelector(".uni-carousel-elements")!;

        this.slideModel = new SlideModel(slideElements)
            .withPrePrev(this.preLeft)
            .withPostPrev(this.postChange)
            .withPreNext(this.preRight)
            .withPostNext(this.postChange);

        const activeIndex = this.slideModel.activeIndex();
        this.markIndicatorActive(activeIndex);
        this.updateCarousel(slideElements[activeIndex]);
    }

    private preLeft = function (this: UniCarouselController, from: IndexedElement, to: IndexedElement): void {
        this.updateIndicators(from, to);
        this.element.classList.add("scrolling", "scroll-left");
        const targetElement = to.element;

        for (const element of this.slideElements) {
            element.classList.remove("previous");
            element.classList.remove("target");
        }
        targetElement.classList.add("previous");
        targetElement.classList.add("target");
        targetElement.classList.remove("inactive");
        if (targetElement.classList.contains("next")) {
            targetElement.classList.remove("next");
            targetElement.previousElementSibling?.classList.add("next");
        }
    }.bind(this);

    private preRight = function (this: UniCarouselController, from: IndexedElement, to: IndexedElement): void {
        this.updateIndicators(from, to);
        this.element.classList.add("scrolling", "scroll-right");
        const targetElement = to.element;

        for (const element of this.slideElements) {
            element.classList.remove("next");
            element.classList.remove("target");
        }
        targetElement.classList.add("next");
        targetElement.classList.add("target");
        targetElement.classList.remove("inactive");
        if (targetElement.classList.contains("previous")) {
            targetElement.classList.remove("previous");
            targetElement.previousElementSibling?.classList.add("previous");
        }
    }.bind(this);

    private updateIndicators(from: IndexedElement, to: IndexedElement): void {
        this.markIndicatorInactive(from.i);
        this.markIndicatorActive(to.i);
    }

    private postChange = function (this: UniCarouselController, from: IndexedElement, to: IndexedElement): void {
        const element = to.element;
        this.updateCarousel(element);
    }.bind(this);

    private isNotScrollable(): boolean {
        return this.element.classList.contains("scrolling") || this.slideElements.length <= 1;
    }

    public scrollRight(): void {
        if (this.isNotScrollable()) {
            return;
        }
        this.animateScrollRight();
    }

    private scrollLeft(): void {
        if (this.isNotScrollable()) {
            return;
        }
        this.animateScrollLeft();
    }

    private scrollToElement(index: number): void {
        const currentIndex = this.slideModel.activeIndex();
        if (index === currentIndex || this.isNotScrollable()) {
            return;
        }

        if (currentIndex < index) {
            this.animateScrollRight(index);
        } else {
            this.animateScrollLeft(index);
        }
    }

    private animateScrollRight(index?: number): void {
        const scrollRightAnimation = new Animation()
            .millisceconds(ANIMATION_DURATION)
            .onStart(this.preRight)
            .onDone(this.postChange);

        this.elementsContainer.style.transform = "translate3d(-100%,0,0)";

        if (typeof index === "number") {
            this.slideModel.selectActive(index, scrollRightAnimation);
        } else {
            this.slideModel.selectNext(scrollRightAnimation);
        }
    }

    private animateScrollLeft(index?: number): void {
        const scrollLeftAnimation = new Animation()
            .millisceconds(ANIMATION_DURATION)
            .onStart(this.preLeft)
            .onDone(this.postChange);

        this.elementsContainer.style.transform = "translate3d(100%,0,0)";

        if (typeof index === "number") {
            this.slideModel.selectActive(index, scrollLeftAnimation);
        } else {
            this.slideModel.selectPrev(scrollLeftAnimation);
        }
    }

    private markIndicatorActive(index: number): void {
        this.element.querySelectorAll(".carousel-indicator").item(index)?.classList.add("carousel-indicator-active");
        this.element.dispatchEvent(eopCustomEvent(UNI_CAROUSEL_ACTIVE_ELEMENT_CHANGE, index));
    }

    private markIndicatorInactive(index: number): void {
        this.element.querySelectorAll(".carousel-indicator").item(index)?.classList.remove("carousel-indicator-active");
    }

    private updateCarousel(activeElement: HTMLElement): void {
        this.element.classList.remove("scrolling", "scroll-left", "scroll-right");
        this.elementsContainer.style.transform = "translate3d(0,0,0)";
        this.slideElements
            .filter(e => e !== activeElement)
            .forEach(e => e.classList.add("inactive"));
        for (const element of this.slideElements) {
            element.classList.remove("next", "previous", "target");
        }
        const nextElement = activeElement.nextElementSibling ?? this.slideElements[0];
        const prevElement = activeElement.previousElementSibling ?? this.slideElements.last();
        nextElement?.classList.add("next");
        prevElement?.classList.add("previous");
    }
}

@customElement("eop-uni-carousel")
export class EopUniCarousel extends UnLitElement {

    private autoscrollPromise: Promise<void>;

    public constructor(private interval: Interval = resolve(Interval)) {
        super();
    }

    public connectedCallback(): void {
        const slideElements: HTMLElement[] = [];
        const container = this.querySelector<HTMLElement>(".uni-carousel-elements")!;
        for (const child of container.children) {
            if (!child.classList.contains("uni-carousel-indicators") && !child.classList.contains("uni-carousel-controls")) {
                child.classList.add("uni-carousel-element");
                slideElements.push(child as HTMLElement);
            }
        }

        const carousel = new UniCarouselController(this, slideElements);

        const autoscrollInterval = this.getAttribute("autoscroll-interval")?.toInt() ?? 0;
        if (autoscrollInterval > 0) {
            this.autoscrollPromise = this.interval.repeat(() => carousel.scrollRight(), autoscrollInterval * 1000);

            this.addEventListener("click", () => {
                this.interval.cancel(this.autoscrollPromise);
            }, {once: true});
            this.addEventListener("touchstart", () => {
                this.interval.cancel(this.autoscrollPromise);
            }, {once: true});
        }
    }

    public disconnectedCallback(): void {
        this.interval.cancel(this.autoscrollPromise);
    }
}