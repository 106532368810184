import {autoRegister} from "../../container";

export type ErrorRequest = {
    message: string;
    stack: string[];
    url: string;
};

@autoRegister()
export class FrontendWatchService {

    public async submitError(request: ErrorRequest, messageOnFail: string): Promise<void> {
        const response = await fetch(
            `/enbw-eop/eop-app-frontend-watch/rest/stable/error/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify(request)
            });

        if (!response.ok) {
            throw new Error(`${messageOnFail} Status: ${response.status} (${response.statusText})`);
        }
    }
}