import type {PropertyMap} from "../utils/objects";
import {PersistentTrigger, TriggerFactory} from "./triggers";
import {QueryParameters} from "../queryParameters";
import {SessionStorage} from "../clientStorage";
import {Nav} from "../nav";
import {autoRegister, resolve} from "../../container";

type Visits = PropertyMap<boolean>;
const CLIENT_STORAGE_PRESENT_URL_PARAMETER_TRIGGER = "TriggerUrlParameter";

export class UrlParameterTrigger extends PersistentTrigger {
    private triggerUrlParameterName: string;

    public constructor(
        element: Element,
        private sessionStorage: SessionStorage,
        private queryParameters: QueryParameters,
        private nav: Nav,
        callback: () => void
    ) {
        super(callback);
        this.triggerUrlParameterName = element.getAttribute("data-trigger-url-parameter-name") ?? "";

        const urlParameterName: string = this.triggerUrlParameterName;
        if (this.triggerUrlParameterIsPresent(urlParameterName) && this.firstVisit()) {
            this.visit();
            this.queryParameters.remove(urlParameterName);
            this.trigger();
        }
    }

    private triggerUrlParameterIsPresent(urlParameterName: string): boolean {
        return this.queryParameters.exists(urlParameterName);
    }

    private firstVisit(): boolean {
        return this.storedVisits()[this.nav.pathname()] === undefined;
    }

    private storedVisits(): Visits {
        return this.sessionStorage.fetch<Visits>(CLIENT_STORAGE_PRESENT_URL_PARAMETER_TRIGGER) ?? {};
    }

    private visit(): void {
        const visits = this.storedVisits();
        visits[this.nav.pathname()] = true;
        this.sessionStorage.save(CLIENT_STORAGE_PRESENT_URL_PARAMETER_TRIGGER, visits);
    }
}

@autoRegister()
export class UrlParameterTriggerFactory implements TriggerFactory {
    public constructor(
        private sessionStorage: SessionStorage = resolve(SessionStorage),
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private nav: Nav = resolve(Nav)
    ) {
    }

    public createTrigger(element: Element, callback: () => void): UrlParameterTrigger {
        return new UrlParameterTrigger(element, this.sessionStorage, this.queryParameters, this.nav, callback);
    }
}