import {SvgCircleBuilder} from "./svg";
import {LocatedNumbers} from "../../common/utils/locatedNumbers";
import {resolve} from "../../container";
import {Timeout} from "../../common/timeout";
import {TemplateModel} from "../../common/template";
import {IntersectionObserverFactory, onceIntersected} from "../../common/observation";
import {ACTIVATION_CHANGE, ActivationChangeEventParams, prepareEvents} from "../../common/utils/events";
import {ManagingResources} from "../../common/lifetime";
import {elementFrom} from "../../common/utils/html";
import {GLOBAL} from "../../common/globals";


const TOTAL_WIDTH = 650;
const CIRCUMFERENCE = 1000;
const RADIUS = CIRCUMFERENCE / (2 * Math.PI);
const INITIAL_OFFSET = 625;
const SEPARATOR_WIDTH = 2;
const FOCUS_DELAY = 1250;

export class DonutChartSector {
    private circleBuilder: SvgCircleBuilder;
    private length: number;

    public constructor(
        private id: string,
        private relativeLength: number,
        private offset: number,
        private effectiveCircumference: number,
        private colorClass: string
    ) {
        this.length = relativeLength * this.effectiveCircumference;

        this.circleBuilder = new SvgCircleBuilder(RADIUS, TOTAL_WIDTH / 2, TOTAL_WIDTH / 2)
            .withAttribute("stroke-dashoffset", -offset);
    }

    public toSvgPath(): SvgCircleBuilder {
        return this.circleBuilder
            .withAttribute("stroke-dasharray", `${this.length} ${CIRCUMFERENCE - this.length}`)
            .withAttribute("index", this.id)
            .withAttribute("class", "donut-chart-sector " + this.colorClass);
    }

    public getLength(): number {
        return this.length;
    }
}

export class DonutChart {
    private chartSectors: DonutChartSector[];

    private constructor() {
        this.chartSectors = [];
    }

    public static from(model: Model): DonutChart {
        let currentInsertPosition = INITIAL_OFFSET;
        const donutChart = new DonutChart();
        const totalValue = model.totalValue();
        const effectiveCircumference = CIRCUMFERENCE - model.sectors.length * SEPARATOR_WIDTH;

        model.sectors.forEach((sector) => {
            const chartSector = new DonutChartSector(
                sector.id,
                sector.value / totalValue,
                currentInsertPosition,
                effectiveCircumference,
                sector.colorClass
            );

            donutChart.chartSectors.push(chartSector);
            currentInsertPosition += chartSector.getLength() + SEPARATOR_WIDTH;
        });
        return donutChart;
    }

    public toSvgElement(): Element {
        const sectorElements = this.chartSectors
            .map(chartSector => chartSector.toSvgPath().toHtml())
            .join("");

        const centerCircle = new SvgCircleBuilder(RADIUS, TOTAL_WIDTH / 2, TOTAL_WIDTH / 2)
            .withAttribute("class", "donut-chart-inner-circle")
            .toHtml();
        const outerCircle = new SvgCircleBuilder(RADIUS, TOTAL_WIDTH / 2, TOTAL_WIDTH / 2)
            .withAttribute("class", "donut-chart-outer-circle")
            .toHtml();
        const animationCircle = new SvgCircleBuilder(RADIUS, TOTAL_WIDTH / 2, TOTAL_WIDTH / 2)
            .withAttribute("class", "donut-chart-animation-circle")
            .toHtml();

        return elementFrom(`
            <svg viewBox='0 0 ${TOTAL_WIDTH} ${TOTAL_WIDTH}'>
            ${outerCircle}
            ${sectorElements}
            ${animationCircle}
            ${centerCircle}
            </svg>`
        );
    }
}

export type SectorConfig = {
    dataLabel: string;
    legendLabel: string;
    value: number;
    customValueLabel: string;
    hint: string;
    colorClass: string;
};

export type SectorModel = {
    id: string;
    dataLabel: string;
    legendLabel: string;
    value: number;
    valueLabel: string;
    hint: string;
    colorClass: string;
};

export class Model {
    public sectors: SectorModel[];

    public constructor(sectorConfigs: SectorConfig[], unit: string, locatedNumbers: LocatedNumbers, colorClasses: string[]) {
        this.sectors = sectorConfigs.map((sectorConfig, index) => ({
            id: index.toString(),
            dataLabel: sectorConfig.dataLabel,
            legendLabel: sectorConfig.legendLabel,
            value: sectorConfig.value,
            valueLabel: sectorConfig.customValueLabel
                ? sectorConfig.customValueLabel
                : locatedNumbers.formatNumber(sectorConfig.value) + " " + unit,
            hint: sectorConfig.hint,
            colorClass: sectorConfig.colorClass ? sectorConfig.colorClass : colorClasses[index % colorClasses.length]
        }));
    }

    public totalValue(): number {
        return this.sectors.map(sector => sector.value)
            .reduce((sum, value) => sum + value, 0);
    }

    public getSectorWithId(id?: string): SectorModel | null {
        return this.sectors.findFirst(sector => sector.id === id) ?? null;
    }
}

export class EopDonutChart extends ManagingResources(HTMLElement) {
    private model: TemplateModel;
    private chartModel: Model;
    private sectorElements: SVGElement[];
    private focusedSector: SectorModel | null;
    private donutChartSvg: HTMLElement;

    public constructor(
        private locatedNumbers: LocatedNumbers = resolve(LocatedNumbers),
        private timeout: Timeout = resolve(Timeout),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const sectorsConfig: SectorConfig[] = this.getAttribute("donut-sectors")?.parseAsJSON<SectorConfig[]>() ?? [];

        if (sectorsConfig.isEmpty()) {
            return;
        }

        const unit = this.getAttribute("unit") ?? "";
        const caption = this.getAttribute("donut-caption");
        const colorClasses: string[] = this.getAttribute("donut-colors")?.parseAsJSON<string[]>() ?? [];

        this.chartModel = new Model(sectorsConfig, unit, this.locatedNumbers, colorClasses);
        const chart = DonutChart.from(this.chartModel);

        this.model = new TemplateModel(`
            <div class="donut-chart-flex-container">
                <div class="donut-chart-svg-container">
                    <div class="donut-chart-svg">
                        <div class="donut-chart-label">
                            <div class="donut-chart-hint" data-container-slot="hint"></div>
                            <div class="donut-chart-value-label" data-container-slot="valueLabel"></div>
                            <div class="donut-chart-data-label" data-container-slot="dataLabel"></div>
                        </div>
                        <div data-slot="svg"></div>
                    </div>
                    <div data-slot="caption" class="donut-caption"></div>
                </div>
                <div class="donut-chart-legend" data-container-slot="legend"></div>
            </div>
        `);

        this.model.connect(this).apply({
            svg: chart.toSvgElement(),
            caption: caption ? new TemplateModel(`<div class="donut-caption">${caption}</div>`).asElement() : document.createTextNode(""),
            legend: this.legendElements()
        });
        this.donutChartSvg = this.querySelector(".donut-chart-svg")!;

        const intersectionObserver = this.intersectionObserverFactory.create(
            onceIntersected(() => this.handleViewportIntersection()), {threshold: .5});
        intersectionObserver.observe(this.donutChartSvg);
        prepareEvents(GLOBAL.window())
            .boundTo(this)
            .on(ACTIVATION_CHANGE, (ev: CustomEvent<ActivationChangeEventParams>) => {
                if (ev.detail.affects(this)) {
                    this.handleViewportIntersection();
                }
            });

        this.sectorElements = [...this.querySelectorAll<SVGElement>(".donut-chart-sector")];
        prepareEvents(this.sectorElements)
            .boundTo(this)
            .on("mouseenter", (event) => {
                if (event.target instanceof SVGElement) {
                    this.focusSector(event.target);
                }
            });
    }

    private handleViewportIntersection(): void {
        if (!this.donutChartSvg.isVisible()) {
            return;
        }
        this.initializeChart();
    }

    private initializeChart(): void {
        this.querySelector(".donut-chart-animation-circle")!.classList.add("animate");
        this.timeout.delay(() => {
            if (this.focusedSector) {
                return;
            }
            this.focusSector(this.sectorElements[0]);
        }, FOCUS_DELAY);
    }

    private legendElements(): EopDonutChartLegendItem[] {
        return this.chartModel.sectors.map(sector => new EopDonutChartLegendItem(sector));
    }

    private focusSector(focusedElement: SVGElement): void {
        const index = focusedElement.getAttribute("index") ?? undefined;
        this.focusedSector = this.chartModel.getSectorWithId(index);
        this.setChartLabel(this.focusedSector!);
        for (const sectorElement of this.sectorElements) {
            sectorElement.classList.remove("active");
        }
        focusedElement.classList.add("active");
    }

    private setChartLabel(sector: SectorModel): void {
        this.model.apply({
            hint: sector.hint,
            valueLabel: sector.valueLabel,
            dataLabel: sector.dataLabel
        });
    }
}

export class EopDonutChartLegendItem extends HTMLElement {
    private model: TemplateModel;

    public constructor(private sector: SectorModel) {
        super();

        this.model = new TemplateModel(`
            <div class="legend-entry ${this.sector.colorClass}">${this.sector.legendLabel}</div>
        `);
    }

    public connectedCallback(): void {
        if (!this.model.isConnected()) {
            this.model.connect(this);
        }
    }
}

customElements.define("eop-donut-chart-legend-item", EopDonutChartLegendItem);
customElements.define("eop-donut-chart", EopDonutChart);