import {LitElement, PropertyValues} from "lit";
import {navigationJumpInsidePageEvent} from "./headerEvents";
import {Nav} from "../../../../common/nav";
import {resolve} from "../../../../container";
import {MutationObserverFactory} from "../../../../common/observation";

export abstract class EopNavigationItemGroup extends LitElement {

    private mutationObserver: MutationObserver;

    protected constructor(
        private nav: Nav = resolve(Nav),
        private mutationObserverFactory: MutationObserverFactory = resolve(MutationObserverFactory)
    ) {
        super();
        this.mutationObserver = this.mutationObserverFactory.create(
            (mutations) => mutations.forEach((mutation) => this.enhanceLinks(mutation.target as HTMLElement))
        );
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.mutationObserver.observe(this, {subtree: true, childList: true, characterData: true});
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.enhanceLinks((this.renderRoot as HTMLElement));
    }

    public disconnectedCallback(): void {
        this.mutationObserver.disconnect();
        super.disconnectedCallback();
    }

    private enhanceLinks(element: HTMLElement): void {
        if (element instanceof HTMLAnchorElement) {
            this.addJumpListener(element);
        }
        element.querySelectorAll("a[href]").forEach(link => {
            this.addJumpListener(link);
        });
    }

    private addJumpListener(link: Element): void {
        link.addEventListener("click", () => {
            const targetHref = link.getAttribute("href")!;
            if (this.nav.isJump(targetHref)) {
                this.dispatchEvent(navigationJumpInsidePageEvent(targetHref));
            }
        });
    }
}