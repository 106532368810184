import {InfiniteScroll, InfiniteScrollConfig, InfiniteScrollFactory} from "../../common/infiniteScroll";
import {SPINNER_ICON} from "./spinner";
import {resolve} from "../../container";
import {Timeout} from "../../common/timeout";
import {elementFrom} from "../../common/utils/html";
import {customElement} from "lit/decorators.js";
import {UnLitElement} from "../../common/elements";


const NO_AUTO_RELOAD_CLASS = "no-auto-reload";
const NO_FURTHER_LOAD_CLASS = "no-further-load";

@customElement("eop-infinite-content")
export class EopInfiniteContent extends UnLitElement {

    private amountLoaded: number;
    private currentIndex: number;
    private maxAutoLoadedElements: number;
    private maxTotalLoadedElements: number;
    private chunkSize: number;
    private elementCount: number;
    private containerElement: HTMLElement;
    private loadableElements: Element[];
    private infiniteScroll: InfiniteScroll;

    public constructor(
        private timeout: Timeout = resolve(Timeout),
        private infiniteScrollFactory: InfiniteScrollFactory = resolve(InfiniteScrollFactory)
    ) {
        super();
        this.amountLoaded = 0;
        this.currentIndex = 0;
        this.maxAutoLoadedElements = 0;
        this.maxTotalLoadedElements = 0;
        this.chunkSize = 0;
        this.elementCount = 0;
        this.loadableElements = [];
    }

    public connectedCallback(): void {
        this.containerElement = this.getAttribute("container-class") ? this.querySelector("." + this.getAttribute("container-class"))! : this;
        this.loadableElements = [...this.querySelectorAll("." + this.getAttribute("content-class"))];
        this.elementCount = this.loadableElements.length;
        this.chunkSize = this.getAttribute("amount-initially-visible")?.toInt() ?? this.elementCount;
        this.maxAutoLoadedElements = this.getAttribute("max-auto-loaded")?.toInt() ?? this.elementCount;
        this.maxTotalLoadedElements = this.getAttribute("cyclic") === "true" ? Infinity : this.elementCount;

        this.amountLoaded = Math.min(this.chunkSize, this.elementCount);
        this.currentIndex = this.amountLoaded;

        const spinnerIconElement = elementFrom(SPINNER_ICON);
        spinnerIconElement.classList.add("spinner", "spinner-md");
        this.querySelector(".tile-sequence-footer")?.append(spinnerIconElement);

        this.querySelector(".fetch-more-button")?.addEventListener("click", () => this.restart());

        [...this.querySelectorAll("." + this.getAttribute("content-class"))]
            .slice(this.amountLoaded)
            .forEach(it => it.remove());

        this.infiniteScroll = this.setupInfiniteScroll();

        if (this.hasElementsToAutoLoad()) {
            this.infiniteScroll.listenToViewport();
        } else {
            this.handleNoFurtherElementsToAutoload();
        }
    }

    private restart(): void {
        this.infiniteScroll.listenToViewport();
        this.classList.remove(NO_AUTO_RELOAD_CLASS);
    }

    private setupInfiniteScroll(): InfiniteScroll {
        const scrollThreshold = this.getAttribute("scroll-threshold")?.toInt() ?? 1000;
        const loadDelay = this.getAttribute("load-delay")?.toInt() ?? 0;
        const expandCallback = (): Promise<void> =>
            this.timeout.delay(() => this.expandContent(), loadDelay);
        const infiniteScrollConfig = new InfiniteScrollConfig()
            .onElement(this)
            .triggerWhenDistanceToBottomBecomesLessThan(scrollThreshold)
            .withElementExpandingCallback(expandCallback);

        return this.infiniteScrollFactory.newInfiniteScroll(infiniteScrollConfig);
    }

    private hasElementsToLoad(): boolean {
        return this.currentIndex < this.maxTotalLoadedElements;
    }

    private mayFurtherAutoLoad(): boolean {
        return this.amountLoaded < this.maxAutoLoadedElements;
    }

    private hasElementsToAutoLoad(): boolean {
        return this.hasElementsToLoad() && this.mayFurtherAutoLoad();
    }

    public expandContent(): void {
        const endIndex = this.currentIndex + this.chunkSize;
        while (this.currentIndex < endIndex && this.hasElementsToAutoLoad()) {
            const nextIndex = this.currentIndex % this.elementCount;
            const nextElement = this.loadableElements[nextIndex].cloneNode(true);

            this.containerElement.append(nextElement);
            this.currentIndex++;
            this.amountLoaded++;
        }
        if (!this.hasElementsToAutoLoad()) {
            this.handleNoFurtherElementsToAutoload();
        }
    }

    private handleNoFurtherElementsToAutoload(): void {
        if (!this.hasElementsToLoad()) {
            this.classList.add(NO_FURTHER_LOAD_CLASS);
        } else if (!this.mayFurtherAutoLoad()) {
            this.classList.add(NO_AUTO_RELOAD_CLASS);
        }

        this.amountLoaded = 0;
        this.infiniteScroll.stopListeningToViewport();
    }
}

