import {GLOBAL} from "../../common/globals";
import {Deferred, EOP_ERRORS, schedule} from "../../common/utils/promises";
import {Load} from "../../common/load";
import {autoRegister, resolve} from "../../container";
import {Configuration} from "../../common/config";
import {IntersectionObserverFactory, onceIntersected} from "../../common/observation";
import {elementFrom} from "../../common/utils/html";
import {LoadAfterConfirmationEvents} from "../../page/elements/loadAfterConfirmation";
import {VideoProgress, VideoTracking, VideoTrackingFactory} from "../../tracking/videoTracking";

const YOUTUBE_NOCOOKIE_HOST = "https://www.youtube-nocookie.com";

export type YoutubeWindow = Window & {
    gapi: any;
    onYouTubeIframeAPIReady: () => void;
};

type VideoInformation = {
    duration?: string;
    title?: string;
    description?: string;
    uploadDate?: string;
};

type RequestResponse = {
    items?: any[];
};

@autoRegister()
export class YoutubeLoader {
    private deferred: Deferred<void>;
    private loadingTriggered: boolean;

    private window: YoutubeWindow;

    public constructor(
        private configuration: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.deferred = new Deferred<void>();
        this.loadingTriggered = false;

        this.window = GLOBAL.window() as YoutubeWindow;
    }

    public run(): Promise<void> {
        if (this.loadingTriggered) {
            return this.deferred.promise;
        }

        const YOUTUBE_URL = this.configuration.get("YOUTUBE_URL");
        const YOUTUBE_KEY = this.configuration.get("YOUTUBE_KEY");

        if (YOUTUBE_URL && YOUTUBE_KEY) {
            this.loadingTriggered = true;
            this.load.script(YOUTUBE_URL)
                .then(() => {
                    this.window.gapi.load("client", () => {
                        this.window.gapi.client.init({
                            apiKey: YOUTUBE_KEY
                        });
                        this.window.gapi.client.load("youtube", "v3", () => {
                            this.deferred.resolve();
                        });
                    });
                }).catch(EOP_ERRORS);
        } else {
            this.deferred.reject("Missing configuration for Youtube API");
        }
        return this.deferred.promise;
    }
}

@autoRegister()
export class YoutubeIframeApiLoader {
    private deferred: Deferred<void>;
    private loaded: boolean;

    private window: YoutubeWindow;

    public constructor(
        private configuration: Configuration = resolve(Configuration),
        private load: Load = resolve(Load)
    ) {
        this.window = GLOBAL.window() as YoutubeWindow;

        this.deferred = new Deferred<void>();
        this.loaded = false;
    }

    public run(): Promise<void> {
        if (this.loaded) {
            return this.deferred.promise;
        }

        const YOUTUBE_IFRAME_API_URL = this.configuration.get("YOUTUBE_IFRAME_API_URL");

        if (YOUTUBE_IFRAME_API_URL) {
            this.window.onYouTubeIframeAPIReady = () => {
                this.loaded = true;
                this.deferred.resolve();
            };
            this.load.script(YOUTUBE_IFRAME_API_URL).catch(EOP_ERRORS);
        } else {
            this.deferred.reject("Missing configuration for Youtube Iframe API");
        }

        return this.deferred.promise;
    }
}

@autoRegister()
export class YoutubeService {
    private readonly initPromise: Promise<void>;
    private youtubeWindow: YoutubeWindow;

    public constructor(
        private youtubeLoader: YoutubeLoader = resolve(YoutubeLoader)) {
        this.youtubeWindow = GLOBAL.window() as YoutubeWindow;
        this.initPromise = this.youtubeLoader.run();
    }

    public async getVideoInfo(videoId: string): Promise<VideoInformation> {
        try {
            await this.initPromise;
            const request: any = this.youtubeWindow.gapi.client.youtube.videos.list({
                id: videoId,
                part: "contentDetails, snippet"
            });
            const requestDeferred: Deferred<any> = new Deferred();
            request.execute((response?: RequestResponse) => {
                const videoInformation: VideoInformation = {};
                if (response?.items?.length === 1) {
                    const video: any = response.items[0];
                    videoInformation.duration = video.contentDetails?.duration;
                    videoInformation.title = video.snippet?.title;
                    videoInformation.description = video.snippet?.description;
                    videoInformation.uploadDate = video.snippet?.publishedAt;
                }
                requestDeferred.resolve(videoInformation);
            });

            return await requestDeferred.promise;
        } catch (error) {
            EOP_ERRORS(error);
            throw error;
        }
    }
}

export class EopSocialYoutubeSeo extends HTMLElement {

    private youtubeService: YoutubeService;
    private loadAfterConfirmationEvents: LoadAfterConfirmationEvents;

    public constructor() {
        super();
        this.youtubeService = resolve(YoutubeService);
        this.loadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents);
    }

    public connectedCallback(): void {
        const videoId = this.getAttribute("video-id");
        if (!videoId) {
            return;
        }

        this.loadAfterConfirmationEvents.onConfirmation("youtube", async () => {
            try {
                const info = await this.youtubeService.getVideoInfo(videoId);
                return this.addVideoMarkup(info);
            } catch (error) {
                return EOP_ERRORS(error);
            }
        });
    }

    private addVideoMarkup(videoInfo: VideoInformation): void {
        this.setAttribute("itemprop", "video");
        this.setAttribute("itemtype", "http://schema.org/VideoObject");
        this.setAttribute("itemscope", "");

        const videoContainer = this.querySelector(".youtube-player");
        if (videoContainer === null) {
            return;
        }

        const containsElement = (elem: Element): boolean => {
            return [...videoContainer.children].includes(elem);
        };

        if (videoInfo.description) {
            const description = elementFrom(`<span itemprop="description">${videoInfo.description}</span>`);
            !containsElement(description) && videoContainer.prepend(description);
        }
        if (videoInfo.uploadDate) {
            const uploadDate = this.metaItemProp("uploadDate", videoInfo.uploadDate);
            !containsElement(uploadDate) && videoContainer.prepend(uploadDate);
        }
        if (videoInfo.duration) {
            const duration = this.metaItemProp("duration", videoInfo.duration);
            !containsElement(duration) && videoContainer.prepend(duration);
        }
        if (videoInfo.title) {
            const title = this.metaItemProp("name", videoInfo.title);
            !containsElement(title) && videoContainer.prepend(title);
        }
    }

    private metaItemProp(itemProp: string, value: string): HTMLElement {
        const htmlElement = elementFrom<HTMLElement>(`<meta itemprop="${itemProp}">`);
        htmlElement.setAttribute("content", value);
        return htmlElement;
    }
}

export class EopYoutubePlayer extends HTMLElement {

    private player: YT.Player;
    private intersectionObserver: IntersectionObserver;
    private intersectionDeferred: Deferred<void>;
    private videoTracking: VideoTracking;

    private youtubeIframeApiLoader: YoutubeIframeApiLoader;
    private videoTrackingFactory: VideoTrackingFactory;
    private loadAfterConfirmationEvents: LoadAfterConfirmationEvents;
    private youtubeService: YoutubeService;
    private intersectionObserverFactory: IntersectionObserverFactory;

    public constructor() {
        super();
        this.youtubeIframeApiLoader = resolve(YoutubeIframeApiLoader);
        this.videoTrackingFactory = resolve(VideoTrackingFactory);
        this.loadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents);
        this.youtubeService = resolve(YoutubeService);
        this.intersectionObserverFactory = resolve(IntersectionObserverFactory);

        this.intersectionDeferred = new Deferred<void>();
    }

    public connectedCallback(): void {
        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(
                () => this.intersectionDeferred.resolve()),
            {threshold: 0, rootMargin: "100% 0% 100% 0%"}
        );
        this.intersectionObserver.observe(this);

        this.loadAfterConfirmationEvents.onConfirmation("youtube", () => {
            return schedule(this.intersectionDeferred.promise.then(async () => {
                await this.youtubeIframeApiLoader.run();
                this.player = new YT.Player(this.querySelector(".video-placeholder")!, {
                    host: YOUTUBE_NOCOOKIE_HOST,
                    videoId: this.getAttribute("video-id") ?? "",
                    events: {
                        onStateChange: (ev) => this.onStateChange(ev)
                    },
                    playerVars: {
                        iv_load_policy: 3,
                        modestbranding: 1,
                        rel: 0,
                        showinfo: 0,
                        origin: this.getAttribute("origin-stage") ?? undefined
                    }
                });
                this.youtubeService.getVideoInfo(this.getAttribute("video-id") ?? "")
                    .then(info => {
                        this.videoTracking = this.videoTrackingFactory.create(info.title ?? "", new YoutubeVideoProgress(this.player));
                    })
                    .catch(EOP_ERRORS);
            }).catch(EOP_ERRORS)).as("iframe-api-loaded");
        });
    }

    public disconnectedCallback(): void {
        if (this.videoTracking) {
            this.videoTracking.disconnect();
        }
        this.intersectionObserver.disconnect();
    }

    private onStateChange(event: YT.OnStateChangeEvent): void {
        const playerState = event.data;
        if (playerState === PlayerState.PLAYING) {
            this.videoTracking.playing();
        }
        if (playerState === PlayerState.PAUSED) {
            this.videoTracking.pause();
        }
        if (playerState === PlayerState.ENDED) {
            this.videoTracking.ended();
        }
    };

}

export enum PlayerState {
    ENDED = 0,
    PLAYING = 1,
    PAUSED = 2,
}

export class YoutubeVideoProgress extends VideoProgress {

    public constructor(private player: YT.Player) {
        super();
    }

    public currentTime = (): number => {
        if (this.player.getCurrentTime) {
            return this.player.getCurrentTime();
        } else {
            return 0;
        }
    };

    public duration = (): number => {
        if (this.player.getDuration) {
            return this.player.getDuration();
        } else {
            return 0;
        }
    };
}


customElements.define("eop-youtube-player", EopYoutubePlayer);
customElements.define("eop-social-youtube-seo", EopSocialYoutubeSeo);