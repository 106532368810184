export function elementFrom<T extends Element>(html: string): T {
    const template = templateContaining(html);
    return importTemplate(template).firstElementChild! as T;
}

export function elementsFrom<T extends Element>(html: string): T[] {
    const template = templateContaining(html);
    return [...importTemplate(template).children].map(it => it as T);
}

export function nodesFrom(html: string): Node[] {
    const template = templateContaining(html);
    return [...importTemplate(template).childNodes];
}

export function nodesFromElements(elements: Element[]): NodeList {
    const fragment = document.createDocumentFragment();
    elements.forEach(elem => fragment.appendChild(elem.cloneNode(true)));
    return fragment.childNodes;
}

export function divContaining(html: string): Element {
    const element = document.createElement("div");
    element.append(...nodesFrom(html));
    return element;
}

export function templateContaining(html: string): HTMLTemplateElement {
    const template = document.createElement("template");
    template.innerHTML = html;
    return template;
}

export function fragmentContaining(html: string): DocumentFragment {
    return importTemplate(templateContaining(html));
}

export function fragmentOf(template: HTMLTemplateElement): DocumentFragment {
    return importTemplate((template.cloneNode(true) as HTMLTemplateElement));
}

function importTemplate(template: HTMLTemplateElement): DocumentFragment {
    return document.importNode(template.content, true);
}