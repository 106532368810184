import {customElement, property, state} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import {ELLIPSIS_IN_SQUARE_BRACKETS} from "../../../bootstrap/common/strings";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {BOOTSTRAP} from "../../../common/resolutionConstants";
import {UnLitElement} from "../../../common/elements";
import {ResizeObserverFactory} from "../../../common/observation";

type WidthLengthRule = {
    fromPixels: number;
    truncationLength: number;
};

export type WidthLengthMapping = WidthLengthRule[];

const WIDTH_LENGTH_MAPPING: WidthLengthMapping = [
    {fromPixels: 0, truncationLength: 70},
    {fromPixels: 400, truncationLength: 80},
    {fromPixels: 480, truncationLength: 120},
    {fromPixels: 520, truncationLength: 170},
    {fromPixels: 600, truncationLength: 200},
    {fromPixels: 690, truncationLength: 300},
    {fromPixels: 730, truncationLength: 400},
    {fromPixels: 820, truncationLength: 500},
    {fromPixels: 880, truncationLength: 600}
];

@customElement("eop-download-description")
export class EopDownloadDescription extends UnLitElement {

    @property({attribute: "text"})
    private text: string;
    @state()
    private widthLengthMapping: WidthLengthMapping;

    @state()
    private truncatedText: string;

    private resizeObserver: ResizeObserver;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory)
    ) {
        super();
        this.widthLengthMapping = WIDTH_LENGTH_MAPPING;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.resizeObserver = this.resizeObserverFactory.create(() => this.updateText());
        this.resizeObserver.observe(this);
        this.updateText();
    }

    public disconnectedCallback(): void {
        this.resizeObserver.disconnect();
        super.disconnectedCallback();
    }

    public render(): TemplateResult {
        return html`
            ${this.truncatedText}
        `;
    }

    private updateText(): void {
        this.truncatedText = this.calculateText();
    }

    private calculateText(): string {
        if (!this.text) {
            return "";
        }

        if (this.resolution.upTo(BOOTSTRAP.XS)) {
            return this.text;
        }

        const targetLength = this.lengthForWidth(this.getBoundingClientRect().width);
        return this.truncate(targetLength);
    }

    private lengthForWidth(width: number): number {
        return this.widthLengthMapping
            .filter(mapping => width >= mapping.fromPixels)
            .last()!
            .truncationLength;
    }

    private truncate(targetLength: number): string {
        return this.text.truncateWordsAfter(targetLength, ELLIPSIS_IN_SQUARE_BRACKETS);
    }

}