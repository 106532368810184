import {html, type TemplateResult} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";

export type HeadlineLevel = `h${1 | 2 | 3 | 4 | 5 | 6}`;

export function headline(level: HeadlineLevel, content: string, classes: string[] = []): TemplateResult {

    switch (level) {
        case "h1":
            return html`
                <h1 class="h1 ${classes.join(" ")}">${unsafeHTML(content)}</h1>
            `;
        case "h2":
            return html`
                <h2 class="h2 ${classes.join(" ")}">${unsafeHTML(content)}</h2>
            `;
        case "h3":
            return html`
                <h3 class="h3 ${classes.join(" ")}">${unsafeHTML(content)}</h3>
            `;
        case "h4":
            return html`
                <h4 class="h4 ${classes.join(" ")}">${unsafeHTML(content)}</h4>
            `;
        case "h5":
            return html`
                <h5 class="h5 ${classes.join(" ")}">${unsafeHTML(content)}</h5>
            `;
        default:
            return html`
                <h6 class="h6 ${classes.join(" ")}">${unsafeHTML(content)}</h6>
            `;
    }
}