import {noop} from "./functions";
import {autoRegister, resolve} from "../../container";

export type PromiseDecorator = <T> (promise: Promise<T>) => Promise<T>;
const NOOP_PROMISE_DECORATOR: PromiseDecorator = p => p;

export class ScopedPromises {
    private decorators: Map<string, PromiseDecorator>;

    public constructor() {
        this.decorators = new Map();
    }

    public on(event: string, decorator: PromiseDecorator): void {
        this.decorators.set(event, decorator);
    }

    public clear(events: string[]): void {
        for (const event of events) {
            this.decorators.delete(event);
        }
    }

    public decoratorFor(event: string): PromiseDecorator {
        return this.decorators.get(event) ?? NOOP_PROMISE_DECORATOR;
    }
}

@autoRegister()
export class Promises {
    private scoped: Map<string, ScopedPromises>;

    public constructor() {
        this.scoped = new Map();
    }

    public forScope(name: string): ScopedPromises {
        let scoped = this.scoped.get(name);
        if (!scoped) {
            scoped = new ScopedPromises();
            this.scoped.set(name, scoped);
        }
        return scoped;
    }

    public on(event: string, decorator: PromiseDecorator): void {
        this.forScope("global").on(event, decorator);
    }

    public decoratorFor(event: string): PromiseDecorator {
        return this.forScope("global").decoratorFor(event);
    }

    public schedule<T>(context: string, promise: Promise<T>): void {
        // no scheduling logic in production
    }
}

@autoRegister()
export class SingletonPromise<T> {
    private promise: Promise<T> | null;

    public of(promiseCallback: () => Promise<T>): Promise<T> {
        if (!this.promise) {
            this.promise = promiseCallback();
            this.resetOnResolution();
        }
        return this.promise;
    }

    private resetOnResolution(): void {
        this.promise?.finally(() => {
            this.promise = null;
        });
    }
}

export const ERROR_REASON_CANCELLED = "eop_cancelled";
export const ERROR_REASON_TEST = "eop_error_test";

export const EOP_ERRORS = (error: any): void => {
    if (error !== ERROR_REASON_CANCELLED && error !== ERROR_REASON_TEST) {
        console.error(error);
    }
};

export const EOP_ERROR_HANDLER = (reason: any, handleErrorCallback: () => void): void => {
    handleErrorCallback();
    EOP_ERRORS(reason);
};

export function promiseFromJsonResponse<T extends Exclude<object, void>>(response: Response): Promise<T> {
    if (response.ok) {
        return response.json();
    } else {
        throw errorFromResponse(response);
    }
}

export async function promiseFromVoidResponse(response: Response): Promise<void> {
    if (!response.ok) {
        throw errorFromResponse(response);
    }
}

function errorFromResponse(response: Response): Error {
    return Error(`Response failed with status ${response.status}: ${response.statusText}`);
}

export class Deferred<T> {

    public promise: Promise<T>;
    public resolve: (value: T | PromiseLike<T>) => void;
    public reject: (reason?: any) => void;

    public constructor() {
        this.resolve = noop;
        this.reject = noop;
        this.promise = new Promise((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
        this.promise.catch(noop);
    }

    public rejectExpected(): void {
        this.reject(ERROR_REASON_CANCELLED);
    }

    public rejectForTestPurpose(): void {
        this.reject(ERROR_REASON_TEST);
    }
}

export function waitTime(millis: number): Promise<void> {
    return new Promise<void>((res, _) => {
        setTimeout(() => {
            res();
        }, millis);
    });
}

export class Scheduling<T> {
    public constructor(private promise: Promise<T>, private promises: Promises = resolve(Promises)) {
    }

    public as(context: string): Promise<T> {
        this.promises.schedule(context, this.promise);
        return this.promise;
    }
}

export function schedule<T>(promise: Promise<T>): Scheduling<T> {
    return new Scheduling(promise);
}