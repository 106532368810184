import {container as globalContainer, InjectionToken} from "tsyringe";
import type {constructor} from "tsyringe/dist/typings/types";

export function resolve<T>(token: InjectionToken<T>): T {
    return globalContainer.resolve<T>(token);
}

export function register<T>(token: constructor<T>): void {
    globalContainer.registerSingleton(token, token);
}

export function registerByName<T>(name: string, token: constructor<T>): void {
    globalContainer.registerSingleton(name, token);
}

export function autoRegister<T>(): (target: constructor<T>) => void {
    return function (target: constructor<T>): void {
        register(target);
    };
}

export function autoRegisterAs<T>(name: string): (target: constructor<T>) => void {
    return function (target: constructor<T>): void {
        registerByName(name, target);
    };
}