import {ViewportListener} from "./viewportListener";
import {Visibility} from "./visibility";
import {autoRegister, resolve} from "../container";
import {EOP_ERRORS, schedule, SingletonPromise} from "./utils/promises";

import {done, fresh} from "./lifetime";

export class InfiniteScroll {

    private active: boolean;

    public constructor(
        private element: HTMLElement,
        private minDistanceToBottom: number,
        private elementExpandCallback: () => Promise<void>,
        private viewportListener: ViewportListener,
        private visibility: Visibility,
        private singletonPromise: SingletonPromise<void> = resolve(SingletonPromise<void>)
    ) {
        this.active = false;
    }

    public listenToViewport(): void {
        const lifetime = fresh(this, "listening");
        this.active = true;
        this.viewportListener.onViewportEvent(() => this.triggerIfNotAlreadyInProgress(), lifetime);
        this.triggerIfNotAlreadyInProgress();
    }

    public stopListeningToViewport(): void {
        this.active = false;
        done(this, "listening");
    }

    private triggerIfNotAlreadyInProgress(): void {
        if (!this.active || !this.tooCloseToBottom()) {
            return;
        }

        this.singletonPromise.of(() =>
            schedule(this.expandElementUntilBottomFarEnough()
                .catch(EOP_ERRORS))
                .as("infinite-scroll")
        );
    }

    private async expandElementUntilBottomFarEnough(): Promise<void> {
        if (this.active && this.tooCloseToBottom()) {
            await this.elementExpandCallback();
            await this.expandElementUntilBottomFarEnough();
        }
    }

    private tooCloseToBottom(): boolean {
        return this.element.isVisible()
            && this.visibility.sizeBelowViewport(this.element) < this.minDistanceToBottom;
    }
}

export class InfiniteScrollConfig {
    public element: HTMLElement;
    public minDistanceToBottom: number;
    public elementExpandCallback: () => Promise<void>;

    public onElement(element: HTMLElement): this {
        this.element = element;
        return this;
    }

    public triggerWhenDistanceToBottomBecomesLessThan(minDistanceToBottom: number): this {
        this.minDistanceToBottom = minDistanceToBottom;
        return this;
    }

    public withElementExpandingCallback(asyncCallback: () => Promise<void>): this {
        this.elementExpandCallback = asyncCallback;
        return this;
    }
}

@autoRegister()
export class InfiniteScrollFactory {

    public constructor(
        private viewportListener: ViewportListener = resolve(ViewportListener),
        private visibility: Visibility = resolve(Visibility)) {
    }

    public newInfiniteScroll(config: InfiniteScrollConfig): InfiniteScroll {
        return new InfiniteScroll(config.element, config.minDistanceToBottom, config.elementExpandCallback, this.viewportListener, this.visibility);
    }

}