import {ShareDataResponse, ShareService} from "./shareService";
import {EOP_ERRORS} from "../../common/utils/promises";
import {autoRegister, resolve} from "../../container";
import {Interval} from "../../common/interval";

export const SHARE_DATA_UPDATE_INTERVAL = 600000; // 10 minutes

export type ShareData = {
    value: number;
    time: number;
    change: number;
    changePercent: number;
};

export interface ShareDataObserver {
    receiveData: (data: ShareData | null) => void;
}

export interface ShareDataSource {
    register: (observer: ShareDataObserver) => void;
}

@autoRegister()
export class BufferingShareDataSource implements ShareDataSource {
    private observers: ShareDataObserver[];

    public constructor(
        private shareService: ShareService = resolve(ShareService),
        private interval: Interval = resolve(Interval)
    ) {
        this.observers = [];
        this.fetch();
        this.interval.repeat(() => this.fetch(), SHARE_DATA_UPDATE_INTERVAL);
    }

    public register(observer: ShareDataObserver): void {
        this.observers.push(observer);
    }

    private fetch(): void {
        this.shareService.fetch()
            .then(response => {
                const data = this.mapResponse(response);
                this.update(data);
            })
            .catch(error => {
                EOP_ERRORS(error);
                this.update(null);
            });
    }

    private update(data: ShareData | null): void {
        this.observers.forEach(observer => observer.receiveData(data));
    }

    private mapResponse(response: ShareDataResponse): ShareData {
        return {
            value: response.LAST,
            time: response.DATETIME_LAST_R,
            change: response.PREVIOUSDAYDIFFVALUE,
            changePercent: response.PREVIOUSDAYDIFFPERCENT
        };
    }
}
