import {ScrollEvents} from "../../../common/scroll";
import {noop} from "../../../common/utils/functions";
import {eventOccurredOutside, KEYS} from "../../../common/utils/events";
import {PageScrollbar} from "../../../common/pageScrollbar";
import {GLOBAL} from "../../../common/globals";
import type {Dictionary, DictionaryElement} from "../dictionary";
import {autoRegister, resolve} from "../../../container";
import {elementFrom} from "../../../common/utils/html";
import {PageFeatures} from "../pageFeatures";
import {done, fresh} from "../../../common/lifetime";
import {NO_CLICK_TARGET_CLASS} from "../../../bootstrap/common/elements";

export const CLOSE_DIALOG_EVENT = "closeDialog";

export class ModalDialog {
    public dialogElement: HTMLElement;
    private bottomCloseButtonSection: HTMLElement;
    private cleanupCallbacks: Array<() => void>;
    private onCloseAction: () => void;
    private onOpenAction: () => void;
    private closeOnOutsideClick: boolean;

    public constructor(
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private scrollService: ScrollEvents = resolve(ScrollEvents),
        private pageFeatures: PageFeatures = resolve(PageFeatures)
    ) {
        this.cleanupCallbacks = [];
        this.onOpenAction = noop;
        this.onCloseAction = () => this.fadeOut();
        this.closeOnOutsideClick = true;

        this.dialogElement = elementFrom(`
            <div class="dialog vertically-scrollable-area ${NO_CLICK_TARGET_CLASS}" tabindex="-1">
                <div class="dialog-container">
                    <div class="dialog-panel">
                        <span class="close-button" data-eventelement="navigation" data-tracking-label="modal-dialog-close"></span>
                        <div class="dialog-body"></div>
                        <div class="bottom-close-section">
                            <button class="secondary" data-eventelement="navigation"><eop-msg key="MSG_CLOSE_DIALOG"></eop-msg></button>
                        </div>
                    </div>
                </div>
            </div>
        `);

        this.bottomCloseButtonSection = this.dialogElement.querySelector(".dialog-panel .bottom-close-section")!;
        this.dialogElement.addEventListener(CLOSE_DIALOG_EVENT, () => this.close());
    }

    public withContent(element: Element | Node[]): this {
        const dialogBody = this.dialogElement.querySelector(".dialog-body")!;
        if (element instanceof Element) {
            dialogBody.appendChild(element);
        } else {
            dialogBody.append(...element);
        }

        const layerSections = dialogBody.querySelectorAll(".layer-section");
        if (layerSections.length > 0) {
            this.bottomCloseButtonSection.classList.add("background");
            const backgroundClass = [...layerSections.item(layerSections.length - 1).classList].findFirst(c => c.includes("background-"));
            if (backgroundClass) {
                this.bottomCloseButtonSection.classList.add(backgroundClass);
            }
        }

        return this;
    }

    public withDictionary(dictionary: Dictionary): this {
        (this.dialogElement as DictionaryElement)._dictionary = dictionary;
        return this;
    }

    public onOpen(callback: () => void): this {
        this.onOpenAction = callback;
        return this;
    }

    public onClose(close: () => void): this {
        this.onCloseAction = () => close();
        return this;
    }

    public closingOnOutsideClick(closeOnOutsideClick: boolean = true): this {
        this.closeOnOutsideClick = closeOnOutsideClick;
        return this;
    }

    public open(): void {
        this.pageFeatures.insert(this.dialogElement, fresh(this, "open"));
        this.registerCleanUpEvent("click", this.dialogElement.querySelector(".dialog-panel .close-button")!, () => this.close());
        this.registerCleanUpEvent("click", this.bottomCloseButtonSection.querySelector("button")!, () => this.close());
        this.registerCleanUpEvent("keydown", GLOBAL.bodyElement(), (event: any) => {
            if (event.key && event.key === KEYS.ESCAPE) {
                event.stopPropagation();
                this.close();
            }
        });
        if (this.closeOnOutsideClick) {
            this.registerCleanUpEvent("click", this.dialogElement, (event: Event) => {
                const panel = this.dialogElement.querySelector(".dialog-panel")!;
                if (eventOccurredOutside(event, panel)) {
                    this.close();
                }
            });
        }

        this.onOpenAction();
        this.pageScrollbar.disablePageScrollability();
        this.scrollService.registerScrollableElement(this.dialogElement);
        this.dialogElement.classList.add("open");
        this.dialogElement.focus();
        this.displayAndRegisterSecondaryCloseButton();
    }

    public close(): void {
        this.onCloseAction();
        this.fadeOut();
    }

    private displayAndRegisterSecondaryCloseButton(): void {
        const dialogPanel = this.dialogElement.querySelector(".dialog-panel")!;
        const innerHeight = dialogPanel.clientHeight;
        if (innerHeight && GLOBAL.window().innerHeight * 1.5 < innerHeight) {
            this.bottomCloseButtonSection.classList.add("present");
        }
    }

    private registerCleanUpEvent(eventName: string, element: Element, callback: (event: Event) => void): void {
        const uniqueCallback = (event: Event): void => callback(event);
        element.addEventListener(eventName, uniqueCallback);

        this.cleanupCallbacks.push(() => element.removeEventListener(eventName, uniqueCallback));
    }

    public fadeOut(): void {
        this.dialogElement.classList.remove("open");
        this.dialogElement.addEventListener("transitionend", this.cleanup);
    }

    private cleanup = (): void => {
        this.pageScrollbar.enablePageScrollability();
        this.cleanupCallbacks.forEach(cleanupCallback => cleanupCallback());
        this.cleanupCallbacks = [];
        this.scrollService.unregisterScrollableElement(this.dialogElement);
        done(this, "open");
        this.dialogElement.removeEventListener("transitionend", this.cleanup);
    };
}

@autoRegister()
export class ModalDialogFactory {

    public create(): ModalDialog {
        return new ModalDialog();
    }
}