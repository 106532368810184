export class EopAdditionalTextToggle extends HTMLElement {

    public constructor() {
        super();
    }

    public connectedCallback(): void {
        this.addEventListener("click", () => {
            this.nextAll().forEach(it => it.classList.add("additional-text-separator-animation"));
            this.remove();
        });
    }
}

customElements.define("eop-additional-text-toggle", EopAdditionalTextToggle);