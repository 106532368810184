import {GLOBAL} from "../../../common/globals";
import type {EopPopover} from "./popover";
import {Resolution} from "../../../common/resolution";
import {resolve} from "../../../container";
import {FeatureDetector} from "../../../common/featureDetector";
import {Page} from "../../../common/page";
import {eventOccurredInside, eventOccurredOutside} from "../../../common/utils/events";
import {BOOTSTRAP} from "../../../common/resolutionConstants";


export const AS_OVERLAY_CLASS = "as-overlay";

const POPOVER_ABOVE_CLASS = "popover-above";
const POPOVER_BELOW_CLASS = "popover-below";
const POPOVER_ON_LEFT_EDGE_CLASS = "popover-on-left-edge";
const POPOVER_ON_RIGHT_EDGE_CLASS = "popover-on-right-edge";
const POPOVER_IN_CENTER = "popover-in-center";

//has to be in sync with the scss variable $popover-arrow-height
const POPOVER_ARROW_HEIGHT = 11;

export interface PopoverDisplayMode {
    open: () => void;

    close: () => void;

    toggle: () => void;
}


export abstract class PresentDisplayMode implements PopoverDisplayMode {
    protected outsideClickHandler: (event: Event) => void;

    protected constructor(protected elements: EopPopover) {
        this.outsideClickHandler = () => {
        };
    }

    public abstract adjustPopoverOffset(): void;

    public open(): void {
        if (this.isOpen()) {
            return;
        }

        this.elements.silentlyShowAfter(() => this.adjustPopoverOffset());

        GLOBAL.bodyElement().addEventListener("click", this.outsideClickHandler);
        GLOBAL.bodyElement().addEventListener("touchstart", this.outsideClickHandler);
        this.elements.popoverContainer.addEventListener("click", this.outsideClickHandler);
        this.elements.popoverContainer.addEventListener("touchstart", this.outsideClickHandler);
    }

    public close(): void {
        if (!this.isOpen()) {
            return;
        }
        GLOBAL.bodyElement().removeEventListener("click", this.outsideClickHandler);
        GLOBAL.bodyElement().removeEventListener("touchstart", this.outsideClickHandler);
        this.elements.popoverContainer.removeEventListener("click", this.outsideClickHandler);
        this.elements.popoverContainer.removeEventListener("touchstart", this.outsideClickHandler);
        this.elements.doHide();
    }

    public toggle(): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }

    protected isOpen(): boolean {
        return this.elements.isPresent();
    }

}

export class AbsentDisplayMode implements PopoverDisplayMode {


    public open(): void {
    }

    public close(): void {
    }

    public toggle(): void {
    }

}

export class TooltipDisplayMode extends PresentDisplayMode {
    private readonly ADDITIONAL_PADDING_MOBILE = 10;
    private readonly ADDITIONAL_PADDING_DESKTOP = 25;

    private popoverHeight: number = 0;
    private popoverWidth: number = 0;

    public constructor(
        elements: EopPopover,
        private resolution: Resolution = resolve(Resolution),
        private features: FeatureDetector = resolve(FeatureDetector),
        private page: Page = resolve(Page)
    ) {
        super(elements);

        if (elements.enforceCloseButton || this.features.isTouchDevice()) {
            elements.popoverCloser.show();
        }

        [elements.managingParent!, elements.popoverContainer]
            .forEach(elm => elm.classList.remove(AS_OVERLAY_CLASS));
        if (this.isOpen()) {
            this.adjustPopoverOffset();
        }
        this.outsideClickHandler = event => {
            if (eventOccurredOutside(event, this.elements.managingParent!) && eventOccurredOutside(event, this.elements)) {
                this.close();
                event.stopPropagation();
            }
        };
    }

    public adjustPopoverOffset(): void {
        this.resetLayout();
        this.setAdditionalPadding();
        this.setPopoverHeight();
        this.setPopoverWidth();
        this.adjustHorizontalOffset();
        this.adjustVerticalOffset();
    }

    private resetLayout(): void {
        [
            POPOVER_ABOVE_CLASS,
            POPOVER_BELOW_CLASS,
            POPOVER_IN_CENTER,
            POPOVER_ON_LEFT_EDGE_CLASS,
            POPOVER_ON_RIGHT_EDGE_CLASS
        ].forEach(
            className => this.elements.popoverContainer.classList.remove(className)
        );
        this.elements.popoverElement.style.left = "";
    }

    private adjustHorizontalOffset(): void {
        if (this.isOnLeftEdge()) {
            this.elements.popoverContainer.classList.add(POPOVER_ON_LEFT_EDGE_CLASS);
        } else if (this.isOnRightEdge()) {
            this.elements.popoverContainer.classList.add(POPOVER_ON_RIGHT_EDGE_CLASS);
        } else {
            this.elements.popoverContainer.classList.add(POPOVER_IN_CENTER);
        }
    }

    private adjustVerticalOffset(): void {
        if (this.isOnTopEdge()) {
            this.elements.popoverContainer.classList.add(POPOVER_BELOW_CLASS);
        } else {
            this.elements.popoverContainer.classList.add(POPOVER_ABOVE_CLASS);
        }
    }

    private isOnLeftEdge(): boolean {
        return this.distanceToLeftEdge() < this.minimumDistanceToEdge();
    }

    private isOnRightEdge(): boolean {
        return this.distanceToRightEdge() < this.minimumDistanceToEdge();
    }

    private minimumDistanceToEdge(): number {
        return this.getAdditionalPadding() + this.getPopoverWidth() * 0.5;
    }

    private isOnTopEdge(): boolean {
        return this.distanceToTopEdge() - this.getPopoverHeight() - POPOVER_ARROW_HEIGHT <= 0;
    }

    private distanceToLeftEdge(): number {
        return this.leftOffset();
    }

    private distanceToRightEdge(): number {
        return this.page.viewportWidth() - this.leftOffset();
    }

    private leftOffset(): number {
        return this.elements.managingParent!.leftOffset();
    }

    private distanceToTopEdge(): number {
        return this.elements.managingParent!.topOffset() - this.page.getEffectiveYScrollPosition();
    }

    private getPopoverWidth(): number {
        return this.popoverWidth;
    }

    private setPopoverWidth(): void {
        this.popoverWidth = this.elements.popoverElement.clientWidth;
    }

    private getPopoverHeight(): number {
        return this.popoverHeight;
    }

    private setPopoverHeight(): void {
        this.popoverHeight = this.elements.popoverElement.clientHeight;
        this.elements.popoverContainer.style.setProperty("--popover-height", `${this.getPopoverHeight()}px`);
    }

    private getAdditionalPadding(): number {
        if (this.resolution.upTo(BOOTSTRAP.XS)) {
            return this.ADDITIONAL_PADDING_MOBILE;
        }
        return this.ADDITIONAL_PADDING_DESKTOP;
    }

    private setAdditionalPadding(): void {
        if (this.resolution.upTo(BOOTSTRAP.XS)) {
            this.elements.popoverContainer.style.setProperty("--additional-padding", `${this.ADDITIONAL_PADDING_MOBILE}px`);
        } else {
            this.elements.popoverContainer.style.setProperty("--additional-padding", `${this.ADDITIONAL_PADDING_DESKTOP}px`);
        }
    }

}

export class OverlayDisplayMode extends PresentDisplayMode {
    public constructor(elements: EopPopover) {
        super(elements);
        [elements.managingParent!, elements.popoverContainer]
            .forEach(elm => elm.classList.add(AS_OVERLAY_CLASS));
        elements.popoverCloser.show();
        if (this.isOpen()) {
            this.adjustPopoverOffset();
        }
        this.outsideClickHandler = event => {
            if (eventOccurredOutside(event, this.elements.managingParent!) ||
                (eventOccurredOutside(event, this.elements.popoverElement)
                    && eventOccurredInside(event, this.elements.popoverContainer))) {
                this.close();
                event.stopPropagation();
            }
        };
    }

    public adjustPopoverOffset(): void {
        this.elements.popoverElement.style.left = "";
        this.elements.popoverContainer.classList.remove(POPOVER_BELOW_CLASS);
        this.elements.popoverContainer.classList.remove(POPOVER_ABOVE_CLASS);
    }

}