import {customElement, property, query} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import {UnLitElement} from "../../common/elements";

@customElement("eop-resettable-input")
export class EopResettableInput extends UnLitElement {

    @property({attribute: "placeholder"})
    private placeholder: string;
    @query("input")
    private input: HTMLInputElement;
    @query("button")
    private resetButton: HTMLButtonElement;

    public render(): TemplateResult {
        return html`
            <input type="text" placeholder=${this.placeholder} @input=${this.toggleResetButton}/>
            <button type="reset" @click=${this.reset} style="display:none" data-tracking-label="reset-input"></button>
        `;
    }

    private toggleResetButton(): void {
        this.resetButton.style.display = this.input.value === ""
            ? "none"
            : "block";
    }

    private reset(): void {
        this.resetButton.style.display = "none";
        this.input.value = "";
        this.input.focus();
    }
}