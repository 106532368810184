import type {PropertyMap} from "../utils/objects";
import {TimerTriggerFactory} from "./timerTrigger";
import {autoRegister, resolve} from "../../container";
import {SyntheticTriggerFactory, TriggerFactory} from "./triggers";
import {ClickTriggerFactory} from "./clickTrigger";
import {InactivityTriggerFactory} from "./inactivityTrigger";
import {UrlParameterTriggerFactory} from "./urlParameterTrigger";
import {ImmediateTriggerFactory} from "./immediateTrigger";
import {ScrollTriggerFactory} from "./scrollTrigger";
import {ScrollAndTimerTriggerFactory, ScrollOrTimerTriggerFactory} from "./scrollTimerTrigger";
import {SessionStorage} from "../clientStorage";
import {QueryParameters} from "../queryParameters";
import {Nav} from "../nav";
import {TimerFactory} from "../../page/elements/timer";
import {Page} from "../page";

@autoRegister()
export class TriggerService {
    private defaultTriggerFactory = new SyntheticTriggerFactory();
    private readonly triggerFactories: PropertyMap<TriggerFactory>;

    public constructor(
        sessionStorage: SessionStorage = resolve(SessionStorage),
        queryParameters: QueryParameters = resolve(QueryParameters),
        nav: Nav = resolve(Nav),
        timerFactory: TimerFactory = resolve(TimerFactory),
        scrollTriggerFactory: ScrollTriggerFactory = resolve(ScrollTriggerFactory),
        timerTriggerFactory: TimerTriggerFactory = resolve(TimerTriggerFactory),
        page: Page = resolve(Page)
    ) {
        this.triggerFactories = {
            TriggerClick: new ClickTriggerFactory(),
            TriggerImmediate: new ImmediateTriggerFactory(),
            TriggerTimer: new TimerTriggerFactory(timerFactory),
            TriggerInactivity: new InactivityTriggerFactory(timerFactory),
            TriggerUrlParameter: new UrlParameterTriggerFactory(sessionStorage, queryParameters, nav),
            TriggerScroll: new ScrollTriggerFactory(page),
            TriggerScrollAndTimer: new ScrollAndTimerTriggerFactory(scrollTriggerFactory, timerTriggerFactory),
            TriggerScrollOrTimer: new ScrollOrTimerTriggerFactory(scrollTriggerFactory, timerTriggerFactory)
        };
    }

    public forEvent(triggerType?: string): TriggerFactory {
        const triggerFactory = triggerType
            ? this.triggerFactories[triggerType]
            : undefined;

        return triggerFactory ?? this.defaultTriggerFactory;
    }
}