import {RenewableEnergyService} from "./renewableEnergyService";
import {RenewableNumberRequest, RenewableNumberResponse} from "./renewableNumberRequest";
import {AnimatedNumber, AnimatedNumberFactory} from "../../page/elements/animatedNumber";
import {isNumber} from "../../bootstrap/common/numbers";
import {resolve} from "../../container";
import {Interval} from "../../common/interval";
import {EopImageSpinner} from "../../page/elements/spinner";
import {schedule} from "../../common/utils/promises";

export const RENEWABLE_NUMBER_REFRESH_INTERVAL = 20000;

export class EopRenewableNumber extends HTMLElement {
    private refreshPromise: Promise<void>;
    private animatedNumber: AnimatedNumber | undefined;
    private precision: number;
    private spinner: Element;

    public constructor(
        private renewableEnergyService: RenewableEnergyService = resolve(RenewableEnergyService),
        private interval: Interval = resolve(Interval),
        private animatedNumberFactory: AnimatedNumberFactory = resolve(AnimatedNumberFactory)
    ) {
        super();
        this.spinner = new EopImageSpinner().withClass("renewable-energy-spinner");
    }

    public connectedCallback(): void {
        this.append(this.spinner);
        this.precision = this.getAttribute("precision")?.toInt() ?? 1;
        const request = new RenewableNumberRequest(
            this.getAttribute("technology") ?? "",
            this.getAttribute("unit") ?? "",
            this.precision);

        this.updateNumber(request);

        this.refreshPromise = this.interval.repeat(
            () => this.updateNumber(request),
            RENEWABLE_NUMBER_REFRESH_INTERVAL);
    }

    public disconnectedCallback(): void {
        this.interval.cancel(this.refreshPromise);
        this.spinner.remove();
    }

    private updateNumber(request: RenewableNumberRequest): void {
        schedule(this.renewableEnergyService.getCurrent(request)
            .then((response: RenewableNumberResponse) => this.getAnimatedNumber().update(this.numberFromResponse(response)))
            .catch(() => this.getAnimatedNumber().update(NaN)))
            .as("update-number");
    }

    private numberFromResponse(response: RenewableNumberResponse): number {
        return isNumber(response.value) ? response.value : NaN;
    }

    private getAnimatedNumber(): AnimatedNumber {
        if (!this.animatedNumber) {
            this.animatedNumber = this.animatedNumberFactory.builder()
                .withFixedPrecision(this.precision)
                .withNumberGroupSeparator(true)
                .withInvalidationText("N/A")
                .appliedTo(this);
        }
        return this.animatedNumber;
    }
}

customElements.define("eop-renewable-number", EopRenewableNumber);