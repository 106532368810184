import {ResolutionSource} from "./image";

export function parseResolutionSource(source: string | undefined): ResolutionSource | undefined {
    if (!source) {
        return undefined;
    }
    const sourceElements = source.split(/[?&]/);
    return sourceElements
        .reduce(
            (info, token) => {
                return info.parseWidth(token)
                    ?? info.parseHeight(token)
                    ?? info.parseFocusX(token)
                    ?? info.parseFocusY(token)
                    ?? info.parseOtherToken(token);
            },
            new ResolutionSource())
        .validate();
}