import {makeId, Tracking} from "./tracking";
import {isString} from "../bootstrap/common/strings";
import type {InteractionTrackingData} from "./trackingData";
import {GLOBAL} from "../common/globals";
import {autoRegister, resolve} from "../container";
import {prepareEvents} from "../common/utils/events";

import {ManagingResources} from "../common/lifetime";

export class TrackingContext {

    public constructor(
        private interacted: Element,
        private eventElement: Element | null,
        private section: Element,
        private customEventName?: string
    ) {
    }

    public static from(event: Event, customEventName?: string): TrackingContext | null {
        const eventPath: HTMLElement[] = event.composedPath()
            .filter(element => element instanceof HTMLElement)
            .map(element => element as HTMLElement);

        const eventSectionElement = eventPath
            .findFirst(it => it.classList.contains("pagesection"));

        if (!eventSectionElement) {
            return null;
        }

        const eventElement = eventPath
            .findFirst(it => it.hasAttribute("data-eventelement")) ?? null;

        if (customEventName) {
            const customTrackedElement = eventPath
                .findFirst(it => it.isVisible());
            if (customTrackedElement) {
                return new TrackingContext(customTrackedElement, eventElement, eventSectionElement, customEventName);
            }
        }

        const clickedElement = eventPath
            .findFirst(it => it.isVisible() && (it.isClickable() || it.hasAttribute("data-tracking-label")));

        if (clickedElement) {
            return new TrackingContext(clickedElement, eventElement, eventSectionElement);
        } else {
            return null;
        }
    }

    public getCustomEventName(): string | null {
        return this.customEventName ?? null;
    }

    public getEventSection(): string {
        const eventSection = this.section.id;
        return makeId(eventSection);
    }

    public getEventName(): string {
        let eventName: string | null = null;
        let current: TrackingContext = this;
        while (!eventName) {
            eventName = current.getCustomEventName()
                ?? current.getEventNameByAttribute("data-tracking-label")
                ?? current.getEventNameByAttribute("title")
                ?? current.getEventNameByTextContent()
                ?? current.getEventNameByAttribute("alt")
                ?? current.getEventNameByImgSrc();

            const nextChild = this.getNextChildForEventName(current);
            if (nextChild) {
                current = new TrackingContext(nextChild, current.eventElement, current.section);
                continue;
            }
            break;
        }

        eventName = eventName
            ?? this.getDefaultTrackingLabelFromEventElement()
            ?? this.compressedNameOfInteractedElement("noeventname");

        return makeId(eventName);
    }

    private getNextChildForEventName(context: TrackingContext): Element | null {
        const clickableChildren = [...context.interacted.children].filter(e => e.isClickable());

        if (context.interacted.children.length === 1) {
            return context.interacted.children.item(0)!;
        }
        if (clickableChildren.length === 1) {
            return clickableChildren[0];
        }
        return null;
    }

    private getEventNameByAttribute(name: string): string | null {
        const eventName = this.interacted.getAttribute(name);
        return this.validNonEmptyString(eventName);
    }

    private getEventNameByTextContent(): string | null {
        const text = [...this.interacted.childNodes]
            .filter(e => e instanceof Text)
            .map(e => e.textContent)
            .join("")
            .trim();

        return this.validNonEmptyString(text);
    }

    private getEventNameByImgSrc(): string | null {
        let imageFileName = this.getEventNameByAttribute("src");
        if (imageFileName) {
            imageFileName = imageFileName.split("/").pop() ?? null;
        }

        return this.validNonEmptyString(imageFileName);
    }

    private getDefaultTrackingLabelFromEventElement(): string | null {
        const eventName = this.eventElement?.getAttribute("data-default-tracking-label");
        return eventName ? this.validNonEmptyString(eventName) : null;
    }

    public getEventElement(): string {
        const eventElement = this.eventElement?.getAttribute("data-eventelement")
            ?? this.compressedNameOfInteractedElement("noeventelement");

        return makeId(eventElement);
    }

    private validNonEmptyString(text: any): string | null {
        if (isString(text) && text.length > 0) {
            const trimmed = text.trim();
            return trimmed.length > 0 ? trimmed : null;
        }
        return null;
    }

    private compressedNameOfInteractedElement(cause: string): string {
        const interacted = this.interacted as HTMLElement;

        let compressed = interacted.tagName.toLowerCase();
        if (interacted.classList.length > 0) {
            compressed += "_" + [...interacted.classList].join("_");
        }
        return cause + "__" + compressed;
    }
}

@autoRegister()
export class InteractionTracker {

    public constructor(private tracking: Tracking = resolve(Tracking)) {
    }

    public trackUserInteraction(event: Event, eventName?: string): void {
        const context = TrackingContext.from(event, eventName);
        if (!context) {
            return;
        }

        const interactionData: InteractionTrackingData = {
            eventElement: context.getEventElement(),
            eventSection: context.getEventSection(),
            eventName: context.getEventName()
        };

        if (interactionData.eventElement.startsWith("noeventelement") || interactionData.eventName.startsWith("noeventname")) {
            console.error("Interaction tracking failed: " + JSON.stringify(interactionData));
        }

        this.tracking.interaction(interactionData);
    }
}

export class EopInteractionTracking extends ManagingResources(HTMLElement) {

    public constructor(private interactionTracker: InteractionTracker = resolve(InteractionTracker)) {
        super();
    }

    public connectedCallback(): void {
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(this)
            .on("click",
                ev => this.interactionTracker.trackUserInteraction(ev),
                {capture: true});
    }
}

customElements.define("eop-interaction-tracking", EopInteractionTracking);