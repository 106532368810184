import {EventBus} from "../../common/eventBus";
import {LocalStorage} from "../../common/clientStorage";
import type {PropertyMap} from "../../common/utils/objects";
import {resolve} from "../../container";
import {Deferred, EOP_ERRORS, schedule} from "../../common/utils/promises";
import {Dictionary} from "./dictionary";
import {EopOverlayImageSpinner, Spinner} from "./spinner";
import {html, LitElement, TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./loadAfterConfirmation.lit.scss";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import type {DirectiveResult} from "lit/directive.js";
import {classMap} from "lit/directives/class-map.js";
import {DefaultLifetime} from "../../common/lifetime";
import {OneTrust, ONETRUST_CONSENT_CHANGED_EVENT, OneTrustConsentChangedEventData} from "../../tracking/onetrust/oneTrust";
import {DatePeriod} from "../../common/utils/dates/datePeriod";
import {LocalDate} from "../../common/utils/dates/localDate";

const DURATION_OF_CONSENT: DatePeriod = DatePeriod.fromDateExpression("90d");

export const CLIENT_STORAGE_CONFIRMED_MODULES = "confirmedModules";
export type ClientStorageConfirmedModules = PropertyMap<boolean>;

export const CONFIRMATION_TO_LOAD_EVENT = "eop-confirmation-to-load-event";
export const LOAD_MODULE_COMPLETE_EVENT = "eop-load-module-complete-event";
export const CONFIRMED_CLASS = "confirmed";

export class LoadAfterConfirmationEvents {
    public constructor(private eventBus: EventBus = resolve(EventBus), private oneTrust: OneTrust = resolve(OneTrust)) {
    }

    public onConfirmation(moduleName: string, callback: () => Promise<any>): void {
        const abortController = new AbortController();
        this.eventBus.on(CONFIRMATION_TO_LOAD_EVENT, (event) => {
            if (event.data === moduleName) {
                callback().then(() => {
                    this.moduleLoaded(moduleName);
                }).catch(EOP_ERRORS);
                abortController.abort();
            }
        }, new DefaultLifetime(abortController));
    }

    public onModuleLoaded(moduleName: string, callback: () => void): void {
        const abortController = new AbortController();
        this.eventBus.on(LOAD_MODULE_COMPLETE_EVENT, (event) => {
            if (event.data === moduleName) {
                callback();
                abortController.abort();
            }
        }, new DefaultLifetime(abortController));
    }

    public onConsentGiven(callback: () => void): void {
        const abortController = new AbortController();
        this.eventBus.on<OneTrustConsentChangedEventData>(ONETRUST_CONSENT_CHANGED_EVENT, event => {
            if (event.data.marketingAccepted) {
                callback();
                abortController.abort();
            }
        }, new DefaultLifetime(abortController));

        schedule(this.oneTrust.isConsentGiven().then(consent => {
            if (consent) {
                callback();
            }
        })).as("is-consent-given");
    }

    public confirmation(moduleName: string): void {
        this.eventBus.dispatchEvent(CONFIRMATION_TO_LOAD_EVENT, moduleName);
    }

    public moduleLoaded(moduleName: string): void {
        this.eventBus.dispatchEvent(LOAD_MODULE_COMPLETE_EVENT, moduleName);
    }
}

@customElement("eop-load-after-confirmation")
export class EopLoadAfterConfirmation extends LitElement {

    public static readonly styles = Styles;


    @property({attribute: "module-name"})
    public moduleName: string;
    @property({attribute: "confirmation-text-key"})
    public confirmationTextKey: string;
    @property({attribute: "button-label-key"})
    public buttonLabelKey: string;
    @property({attribute: "placeholder-image-src"})
    public placeholderImageSrc: string;
    @property({attribute: "nested"})
    public nested: boolean;
    @property({attribute: "unblock-from-cookie-consent"})
    public unblockFromCookieConsent: boolean;

    private dictionary: Dictionary;
    private loading: Deferred<void>;
    private spinner: Spinner;

    public constructor(
        private loadConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents),
        private storage: LocalStorage = resolve(LocalStorage)
    ) {
        super();

        this.loading = new Deferred<void>();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.dictionary = Dictionary.of(this);
        this.spinner = new EopOverlayImageSpinner().withClass("spinner-md");

        if (!this.moduleName) {
            return;
        }

        if (this.isModuleConfirmed()) {
            this.confirmModule();
        }

        this.loadConfirmationEvents.onModuleLoaded(this.moduleName, () => {
            this.loading.resolve();
            this.classList.add(CONFIRMED_CLASS);
        });

        this.loadConfirmationEvents.onConsentGiven(() => {
            if (this.unblockFromCookieConsent) {
                this.confirmModuleFromCookieConsent();
            }
        });
    }

    public render(): TemplateResult | null {
        if (!this.moduleName) {
            return null;
        }

        const classes = {
            "with-cover-image": this.hasPlaceHolderImageCover(),
            "nested-mode": this.nested
        };
        return html`
            <div class="load-after-confirmation ${classMap(classes)}">
                <slot name="content-to-confirm" class="content-to-confirm"></slot>
                <div class="placeholder-cover">${this.placeHolderImageCover()}</div>
                <div class="confirmation-overlay">
                    <div class="confirmation-overlay-panel" data-eventelement="confirmation-overlay">
                        <div class="confirmation-overlay-message">${this.confirmationOverlayMessage()}</div>
                        <button type="button" @click=${this.confirmModule}
                                class="uni-button secondary confirm-button">${this.confirmButtonLabel()}
                        </button>
                    </div>
                </div>
                ${this.spinner}
            </div>
        `;
    }

    private confirmModule(): void {
        schedule(this.spinner.spinWhile(this.loading.promise))
            .as(this.moduleName);

        const confirmedModules = this.loadConfirmedModules();
        confirmedModules[this.moduleName] = true;
        this.saveConfirmedModules(confirmedModules);

        this.loadConfirmationEvents.confirmation(this.moduleName);
        this.classList.add(CONFIRMED_CLASS);
    }

    private confirmModuleFromCookieConsent(): void {
        schedule(this.spinner.spinWhile(this.loading.promise))
            .as(this.moduleName);

        this.loadConfirmationEvents.confirmation(this.moduleName);
        this.classList.add(CONFIRMED_CLASS);
    }

    private isModuleConfirmed(): boolean {
        const confirmedModules = this.loadConfirmedModules();
        const moduleConfirmed = confirmedModules[this.moduleName];

        return moduleConfirmed ?? false;
    }

    private placeHolderImageCover(): TemplateResult | Text {
        if (!this.hasPlaceHolderImageCover()) {
            return document.createTextNode("");
        } else {
            return html`
                <eop-responsive-image image-src=${this.placeholderImageSrc} image-alt=${this.moduleName}></eop-responsive-image>`;
        }
    }

    private hasPlaceHolderImageCover(): boolean {
        return !!this.placeholderImageSrc && this.placeholderImageSrc !== "";
    }

    private confirmationOverlayMessage(): DirectiveResult {
        return unsafeHTML(this.dictionary.translate(this.confirmationTextKey ?? ""));
    }

    private confirmButtonLabel(): string {
        return this.dictionary.translate(this.buttonLabelKey ?? "");
    }

    private loadConfirmedModules(): ClientStorageConfirmedModules {
        return this.storage.fetch<ClientStorageConfirmedModules>(CLIENT_STORAGE_CONFIRMED_MODULES) ?? {};
    }

    private saveConfirmedModules(confirmedModules: PropertyMap<boolean>): void {
        this.storage.save(CLIENT_STORAGE_CONFIRMED_MODULES, confirmedModules, {until: LocalDate.today().add(DURATION_OF_CONSENT).toDate()!.getTime()});
    }
}