import {customElement, property, query, queryAll, queryAssignedElements, state} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import Styles from "./tabNavigation.lit.scss";
import {SCROLL_DURATION, ScrollableBar} from "./scrollableBar";

@customElement("eop-tabs")
export class EopTabs extends ScrollableBar {

    public static readonly styles = Styles;

    @property({attribute: "tab-layout", type: String})
    public layout: string = "horizontal";
    @property({attribute: "baseplate", reflect: true, type: Boolean})
    public baseplate: boolean = false;
    @queryAssignedElements({slot: "tab-content"})
    private tabContents: Array<HTMLElement>;
    @query(".tabs")
    protected scrollableArea: HTMLElement;
    @queryAll(".tab .tab-label")
    private tabs: HTMLElement[];
    @state()
    private activeTabIndex: number = 0;

    private tabLabels: string[];

    public connectedCallback(): void {
        super.connectedCallback();

        this.tabLabels = [];
        Array.from(this.querySelectorAll("div[slot='tab-content']").entries()).forEach(([, tabContent], index) => {
            this.tabLabels.push(tabContent.getAttribute("title")!);
            if (index === 0) {
                tabContent.classList.add("active");
            }
            if (this.layout === "vertical") {
                tabContent.classList.add("layout-element-nested-8");
            }
        });
    }

    public render(): TemplateResult | undefined {
        return html`
            <div class="tab-container ${this.layout}">
                <nav class="tab-navigation">
                    <ul class="tabs horizontally-scrollable-area"
                        @scroll=${this.toggleScrollButtonVisibility}
                        @touchend=${{handleEvent: () => this.toggleScrollButtonVisibility(), passive: true}}
                    >
                        ${(this.renderTabLabels())}
                        <eop-swipe-hint></eop-swipe-hint>
                    </ul>
                    ${this.renderScrollLeftButton()}
                    ${this.renderScrollRightButton()}
                </nav>
                ${this.renderSlots()}
            </div>
        `;
    }

    private renderTabLabels(): TemplateResult[] {
        return this.tabLabels.map((tabLabel, index) => {
            const handleTabClick = (e: Event): void => this.clickTab(e, index);
            return html`
                <li role="tab" class="tab ${this.isActivateTab(index) ? "active" : ""}">
                    <div class="tab-label" @click=${handleTabClick}>${tabLabel}</div>
                </li>`;
        });
    }

    private renderSlots(): TemplateResult {
        return html`
            <div class="tab-contents">
                <slot name="tab-content" class="tab-content"></slot>
            </div>
        `;
    }

    private clickTab(event: Event, index: number): void {
        event.preventDefault();

        this.activeTabIndex = index;
        this.tabContents.forEach(tabContent => tabContent.classList.remove("active"));
        this.tabContents[index].classList.add("active");
        this.scrollToTab(index);
    }

    private isActivateTab(index: number): boolean {
        return this.activeTabIndex === index;
    }

    private scrollToTab(index: number): void {
        this.scrollService.enclosingHorizontalArea(this.scrollableArea)
            .scrollToElementCentered(this.tabs[index], SCROLL_DURATION);
    }
}