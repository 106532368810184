import {customElement} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./surveyStatistics.lit.scss";
import type {QuestionCoordinates, StatisticsPerOption} from "./abstractQuestion";
import {SurveyGetStatisticsRequest, SurveyService, SurveyStatisticsForQuestionResponse} from "./surveyService";
import {resolve} from "../../container";
import {EOP_ERRORS, Promises} from "../../common/utils/promises";
import {elementFrom} from "../../common/utils/html";
import {getQuestionFrom} from "./survey";


@customElement("eop-survey-statistics")
export class EopSurveyStatistics extends LitElement {

    public static readonly styles = Styles;

    public constructor(
        private resultElement: Element,
        protected questionData: QuestionCoordinates
    ) {
        super();
    }

    public render(): TemplateResult {
        return html`
            ${(this.renderQuestionTopic())}
            ${(this.renderQuestionText())}
            <div class="survey-result survey-result-phase">${this.resultElement}</div>
        `;
    }

    private renderQuestionTopic(): TemplateResult | null {
        if (!this.questionData.topic) {
            return null;
        }

        return html`
            <div class="survey-question-topic">${this.questionData.topic}</div>`;
    }

    private renderQuestionText(): TemplateResult | null {
        if (!this.questionData.text) {
            return null;
        }

        return html`
            <div class="survey-question-text">${this.questionData.text}</div>`;
    }
}

export class EopSurveyResultOverview extends HTMLElement {

    private language: string;
    private panelStyle: string;

    public constructor(
        private surveyService: SurveyService = resolve(SurveyService),
        private promises: Promises = resolve(Promises)
    ) {
        super();
    }

    public connectedCallback(): void {
        this.language = this.getAttribute("language") ?? "";
        this.panelStyle = this.getAttribute("panel-style") ?? "";
        this.fetchAndDisplayStatistics().catch(EOP_ERRORS);
    }

    private async fetchAndDisplayStatistics(): Promise<void> {
        const response = await this.promises.decoratorFor("result-overview")(this.surveyService.getStatistics(new SurveyGetStatisticsRequest(this.language)));

        const statisticsElements = response.items
            .map(statisticsForQuestion => this.statisticsPanelFrom(statisticsForQuestion));
        this.append(...statisticsElements);
    }

    private statisticsPanelFrom(statisticsForQuestion: SurveyStatisticsForQuestionResponse): Element {
        const question = getQuestionFrom(statisticsForQuestion.question);
        const statisticsElement = question.createStatisticsElement(statisticsForQuestion.options as StatisticsPerOption);

        const panelElement = elementFrom(`<div class="survey-panel ${this.panelStyle}"></div>`);
        panelElement.appendChild(statisticsElement);
        return panelElement;
    }
}

customElements.define("eop-survey-result-overview", EopSurveyResultOverview);