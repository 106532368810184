import {autoRegister, resolve} from "../../../container";
import {GLOBAL} from "../../../common/globals";
import {Page} from "../../../common/page";
import {ResizeObserverFactory} from "../../../common/observation";
import {noop} from "../../../common/utils/functions";

export const STICKY_HEADER_PRIORITY = 1;
export const CHAPTER_NAVIGATION_PRIORITY = 2;

export class StickyPriorityElement {
    public element: HTMLElement;
    public priority: number;
    public stickyClass: string;
    public scrollSectionsUnderMidpointOfElement: boolean = false;
    public elementHeightProvider: () => number;
    public enableAction: () => void = noop;
    public disableAction: () => void = noop;

    public constructor(element: HTMLElement) {
        this.element = element;
    }

    public withPriority(priority: number): this {
        this.priority = priority;
        return this;
    }

    public withStickyClass(stickyClass: string): this {
        this.stickyClass = stickyClass;
        return this;
    }

    public withElementHeightProvider(heightProvider: () => number): this {
        this.elementHeightProvider = heightProvider;
        return this;
    }

    public withEnableAction(enableAction: () => void): this {
        this.enableAction = enableAction;
        return this;
    }

    public withDisableAction(disableAction: () => void): this {
        this.disableAction = disableAction;
        return this;
    }

    public withScrollSectionsUnderMidpointOfElement(): this {
        this.scrollSectionsUnderMidpointOfElement = true;
        return this;
    }
}

@autoRegister()
export class StickyElements {
    private resizeObserver: ResizeObserver;
    private elements: StickyPriorityElement[] = [];
    private currentPrioElement: StickyPriorityElement;

    public constructor(
        private page: Page = resolve(Page),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory)
    ) {
        this.resizeObserver = this.resizeObserverFactory.create(() => {
            this.removeScrollMarginsOnSections();
            this.adjustScrollMarginsOnSections();
        });
    }

    public register(stickyPriorityElement: StickyPriorityElement): void {
        this.elements.push(stickyPriorityElement);
        const newPrioElement = this.determineNewPrioElement();
        if (this.currentPrioElement === undefined) {
            this.enableStickyElement(newPrioElement);
        } else if (newPrioElement !== this.currentPrioElement) {
            this.disableStickyElement(this.currentPrioElement);
            this.enableStickyElement(newPrioElement);
        }
    }

    public unregister(element: Element): void {
        const elementToUnregister = this.elements.findFirst(elem => element === elem.element);
        if (elementToUnregister) {
            this.elements.removeAll(elementToUnregister);
            if (this.elements.isEmpty()) {
                this.disableStickyElement(this.currentPrioElement);
                return;
            }

            const newPrioElement = this.determineNewPrioElement();
            if (newPrioElement !== this.currentPrioElement) {
                this.disableStickyElement(this.currentPrioElement);
                this.enableStickyElement(newPrioElement);
            }
        }
    }

    private disableStickyElement(element: StickyPriorityElement): void {
        element.disableAction();
        element.element.classList.remove(element.stickyClass);
        this.resizeObserver.unobserve(element.element);
        this.removeScrollMarginsOnSections();
        this.page.unregisterViewportOffsetProvider();
    }

    private enableStickyElement(element: StickyPriorityElement): void {
        this.currentPrioElement = element;
        element.enableAction();
        element.element.classList.add(element.stickyClass);
        this.resizeObserver.observe(element.element);
        this.adjustScrollMarginsOnSections();
        this.page.registerViewportOffsetProvider(() => -this.elementHeight());
    }

    private determineNewPrioElement(): StickyPriorityElement {
        this.elements.sort((elemA, elemB) => elemA.priority - elemB.priority);
        return this.elements.last()!;
    }

    private adjustScrollMarginsOnSections(): void {
        this.sectionElements()
            .filter(element => element.offsetTop >= this.scrollThreshold())
            .forEach(element => {
                element.style.scrollMarginTop = "";
                const initialScrollMargin = GLOBAL.window().getComputedStyle(element).scrollMarginTop?.toInt() ?? 0;
                const height = this.currentPrioElement.scrollSectionsUnderMidpointOfElement ? this.elementHeight() / 2 : this.elementHeight();
                element.style.scrollMarginTop = (initialScrollMargin + height).toString() + "px";
            });
    }

    private removeScrollMarginsOnSections(): void {
        this.sectionElements()
            .forEach(element => element.style.scrollMarginTop = "");
    }

    private sectionElements(): HTMLElement[] {
        return [...GLOBAL.bodyElement().querySelectorAll<HTMLElement>("section")];
    }

    private scrollThreshold(): number {
        return this.currentPrioElement.element.getBoundingClientRect().top ?? 0;
    }

    private elementHeight(): number {
        return this.currentPrioElement.elementHeightProvider !== undefined ?
            this.currentPrioElement.elementHeightProvider() :
            this.currentPrioElement.element.getBoundingClientRect().height;
    }
}