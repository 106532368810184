import {Resolution} from "../../common/resolution";
import {BOOTSTRAP} from "../../common/resolutionConstants";
import {resolve} from "../../container";
import {ManagingResources} from "../../common/lifetime";

const CONVERSION_FACTOR = 12;

export class EopSmartImageHeap extends ManagingResources(HTMLElement) {
    private heightPerColumnWidth: number;
    private tiles: Tile[] = [];
    private resolution: Resolution;

    public constructor(
        resolution: Resolution = resolve(Resolution)
    ) {
        super();
        this.resolution = resolution;
    }

    public connectedCallback(): void {
        const config = this.getAttribute("config")!.parseAsJSON<TilesConfig>();
        const tileConfigs = config.tiles;
        this.heightPerColumnWidth = config.totalHeight;

        for (const tileConfig of tileConfigs) {
            const tile = this.querySelector("[id='" + tileConfig.id + "']");
            if (!tile) {
                continue;
            }
            const tileElement = tile.querySelector<HTMLElement>(".smart-image-heap-tile")!;
            const indicator = tile.querySelector<HTMLElement>(".indicator-link");

            this.tiles.push(new Tile(tileElement, indicator, tileConfig));
        }

        if (this.resolution.downTo(BOOTSTRAP.MD)) {
            this.layout();
        }

        this.resolution.onWindowResize(() => {
            if (this.resolution.downTo(BOOTSTRAP.MD)) {
                this.layout();
            } else {
                this.removeLayout();
            }
        }, this);
    }

    private layout(): void {
        const columnWidth = this.computedStyle().width.toInt() / CONVERSION_FACTOR;
        this.style.height = `${this.heightPerColumnWidth * columnWidth}px`;
        this.tiles.forEach(tile => tile.layout(columnWidth));
        this.classList.remove("raw-tiles");
    }

    private removeLayout(): void {
        this.removeAttribute("style");
        this.tiles.forEach(tile => tile.removeLayout());
    }
}

export type TilesConfig = {
    tiles: TileConfig[];
    totalHeight: number;
};

export type TileConfig = {
    id: string;
    width: number;
    height: number;
    left: number;
    top: number;
    indicatorX: number;
    indicatorY: number;
};

export class Tile {
    private width: number;
    private height: number;
    private left: number;
    private top: number;
    private indicatorX: number;
    private indicatorY: number;

    public constructor(private tileElement: HTMLElement, private indicatorElement: HTMLElement | null, config: TileConfig) {
        this.width = config.width;
        this.height = config.height;
        this.left = config.left;
        this.top = config.top;
        this.indicatorX = config.indicatorX;
        this.indicatorY = config.indicatorY;
    }

    public layout(scale: number): void {
        this.tileElement.style.width = `${this.width * scale}px`;
        this.tileElement.style.height = `${this.height * scale}px`;
        this.tileElement.style.left = `${this.left * scale}px`;
        this.tileElement.style.top = `${this.top * scale}px`;

        if (this.indicatorElement) {
            this.indicatorElement.style.top = `${this.relativeIndicatorY(scale)}px`;
            this.indicatorElement.style.left = `${this.relativeIndicatorX(scale)}px`;
        }
    }

    private relativeIndicatorY(scale: number): number {
        return (this.indicatorY - this.top) * scale;
    }

    private relativeIndicatorX(scale: number): number {
        const padding = parseInt(this.tileElement.computedStyle().padding) ?? 0;
        const paddingCorrection = this.isIndicatorOnLeftEdge() ? padding : -padding;
        return (this.indicatorX - this.left) * scale + paddingCorrection;
    }

    private isIndicatorOnLeftEdge(): boolean {
        return this.indicatorX === this.left;
    }

    public removeLayout(): void {
        this.tileElement.removeAttribute("style");
        this.indicatorElement?.removeAttribute("style");
    }
}

customElements.define("eop-smart-image-heap", EopSmartImageHeap);