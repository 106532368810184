import {Resolution} from "../../../common/resolution";
import {BOOTSTRAP, BootstrapBreakpoint} from "../../../common/resolutionConstants";
import {resolve} from "../../../container";
import {customElement, property, query} from "lit/decorators.js";
import {html, LitElement, PropertyValues, TemplateResult} from "lit";
import Styles from "./popover.lit.scss";
import type {PopoverDisplayMode} from "./popoverDisplayMode";
import {AbsentDisplayMode, OverlayDisplayMode, TooltipDisplayMode} from "./popoverDisplayMode";
import {templateContent} from "lit/directives/template-content.js";

import {ManagingResources} from "../../../common/lifetime";
import {NO_CLICK_TARGET_CLASS} from "../../../bootstrap/common/elements";

@customElement("eop-popover")
export class EopPopover extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @query(".popover-container")
    public popoverContainer: HTMLElement;
    @query(".popover")
    public popoverElement: HTMLElement;
    @query(".close-button")
    public popoverCloser: HTMLElement;
    @property({attribute: "mobile-behaviour"})
    public mobileBehaviour: string;
    @property({attribute: "enforce-close-button", type: Boolean})
    public enforceCloseButton: boolean = false;
    @property({attribute: "content"})
    public contentId: string;

    public managingParent?: HTMLElement;
    private displayMode: PopoverDisplayMode;

    public constructor(
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
        this.displayMode = new AbsentDisplayMode();
        this.resolution.onBootstrapBreakpointChange((bp: BootstrapBreakpoint) => this.adjustDisplayMode(bp), this);
    }

    private adjustDisplayMode(bp: BootstrapBreakpoint = this.resolution.getBootstrapBreakpoint()): void {
        if (!this.managingParent || !this.popoverContainer) {
            if (!(this.displayMode instanceof AbsentDisplayMode)) {
                this.displayMode.close();
                this.displayMode = new AbsentDisplayMode();
            }
        } else if (this.shouldBeOverlay(bp)) {
            if (!(this.displayMode instanceof OverlayDisplayMode)) {
                this.displayMode.close();
                this.displayMode = new OverlayDisplayMode(this);
            }
        } else {
            if (!(this.displayMode instanceof TooltipDisplayMode)) {
                this.displayMode.close();
                this.displayMode = new TooltipDisplayMode(this);
            }
        }
    }

    public render(): TemplateResult {
        const template = this.resolveTemplateElement(this.contentId);
        return html`
            <span class="popover-container ${this.mobileBehaviour} ${NO_CLICK_TARGET_CLASS}" data-eventelement="popover-toggle">
                <span class="popover rich-text">
                    <span class="close-button"
                          data-tracking-label="popover-close-button"
                          @click=${this.handleClick}
                    ></span>
                    ${templateContent(template)}
                </span>
            </span>`;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.adjustDisplayMode();
    }

    private handleClick(event: Event): void {
        event.stopPropagation();
        this.close();
    }

    public onParent(parent: HTMLElement): void {
        this.managingParent = parent;
        this.adjustDisplayMode();
    }

    public silentlyShowAfter(action: () => void): void {
        this.popoverContainer.style.visibility = "hidden";
        this.popoverContainer.show();
        action();
        this.popoverContainer.style.visibility = "";
    }

    public doHide(): void {
        this.popoverContainer.hide();
    }

    public isPresent(): boolean {
        return this.popoverContainer.isVisible();
    }

    private shouldBeOverlay(bp: BootstrapBreakpoint): boolean {
        return this.shouldBeOverlayOnMobile() && bp === BOOTSTRAP.XS;
    }

    public shouldBeOverlayOnMobile(): boolean {
        return this.popoverContainer?.classList.contains("overlay-on-mobile");
    }

    public open(): void {
        this.displayMode.open();
    }

    public close(): void {
        this.displayMode.close();
    }

    public toggle(): void {
        this.displayMode.toggle();
    }

    public isModal(): boolean {
        return this.displayMode instanceof OverlayDisplayMode;
    }

    public isHint(): boolean {
        return this.displayMode instanceof TooltipDisplayMode;
    }
}