import {noop} from "./functions";
import type {Lifetime} from "../lifetime";
import {isArray} from "../../bootstrap/common/arrays";


export const ACTIVATION_CHANGE = "activationChange";

declare global {
    interface CustomEventMap {
        "activationChange": CustomEvent<ActivationChangeEventParams>;
    }
}

export class ActivationChangeEventParams {
    public constructor(
        public transition: "init" | "hide" | "show" | "animate",
        private parent: Element,
        private scopeClass?: string) {
    }

    public affects(element: Element): boolean {
        if (element === this.parent) {
            return true;
        }
        const scope = element.parentElement?.closestThat(e =>
            (this.scopeClass && e.classList.contains(this.scopeClass))
            || !(e instanceof HTMLElement)
            || e === this.parent);
        return scope === this.parent;
    }

}

export function eventOccurredOn(event: Event, element: Element): boolean {
    return event.composedPath().first() === element;
}

export function eventOccurredInside(event: Event, element: Element): boolean {
    return event.composedPath()
        .filter(target => target instanceof Element)
        .some(target => target === element || (target as Element).hasAncestor(element, {transparent: true}));
}

export function eventOccurredOutside(event: Event, element: Element): boolean {
    return !eventOccurredInside(event, element);
}

export function eventOccurredInsideAll(event: Event, ...elements: Element[]): boolean {
    return elements.some(element => eventOccurredInside(event, element));
}

export function eventOccurredOutsideAll(event: Event, ...elements: Element[]): boolean {
    return elements.every(element => eventOccurredOutside(event, element));
}

export function isMouseClick(event?: MouseEvent): boolean {
    return event?.detail !== undefined && event.detail > 0;
}

export const KEYS = {
    ENTER: "Enter",
    ESCAPE: "Escape"
};

export const CLICK = {
    STOP_PROPAGATION: false,
    CONTINUE_PROPAGATION: true
};

export const MINIMUM_HORIZONTAL_SWIPE_DISTANCE = 50;
export const MINIMUM_VERTICAL_SCROLL_DISTANCE = 30;

export class HorizontalSwipeHandler {
    private posX: number = 0;
    private posY: number = 0;
    private swipeTarget: Element | undefined = undefined;
    private swipeLeftCallback: (event: Event, target: Element, index: number) => void = noop;
    private swipeRightCallback: (event: Event, target: Element, index: number) => void = noop;

    public constructor(private elements: Element[]) {
    }

    public onSwipeLeft(callback: (event: Event, target: Element, index: number) => void): this {
        this.swipeLeftCallback = callback;
        return this;
    }

    public onSwipeRight(callback: (event: Event, target: Element, index: number) => void): this {
        this.swipeRightCallback = callback;
        return this;
    }

    public activate(): void {
        this.elements.forEach((e, i) => {
            e.addEventListener("touchstart", event => this.swipeStart(event));
            e.addEventListener("touchmove", event => this.swipeMove(event, i));
            e.addEventListener("touchend", _ => this.swipeEnd());
        });
    }

    private isSwipeLeft(event: Event): boolean {
        return !this.isVerticalScroll(event)
            && this.posX - this.determinePosX(event) >= MINIMUM_HORIZONTAL_SWIPE_DISTANCE;
    }

    private isSwipeRight(event: Event): boolean {
        return !this.isVerticalScroll(event)
            && this.determinePosX(event) - this.posX >= MINIMUM_HORIZONTAL_SWIPE_DISTANCE;
    }

    private swipeStart(event: Event): void {
        this.swipeTarget = event.currentTarget instanceof Element ? event.currentTarget : undefined;
        this.posX = this.determinePosX(event);
        this.posY = this.determinePosY(event);
    }

    private swipeMove(event: Event, index: number): void {
        if (!this.swipeTarget) {
            return;
        }
        if (this.isSwipeLeft(event)) {
            if (event.cancelable) {
                event.preventDefault();
            }
            this.swipeLeftCallback(event, this.swipeTarget, index);
            this.swipeTarget = undefined;
        } else if (this.isSwipeRight(event)) {
            if (event.cancelable) {
                event.preventDefault();
            }
            this.swipeRightCallback(event, this.swipeTarget, index);
            this.swipeTarget = undefined;
        }
    }

    private swipeEnd(): void {
        this.swipeTarget = undefined;
        this.posX = 0;
        this.posY = 0;
    }

    private isVerticalScroll(event: Event): boolean {
        return Math.abs(this.posY - this.determinePosY(event)) >= MINIMUM_VERTICAL_SCROLL_DISTANCE;
    }

    private determinePosX(event: Event): number {
        if (!(event instanceof TouchEvent) || event.changedTouches.length === 0) {
            return 0;
        }
        return event.changedTouches[0].clientX ?? 0;
    }

    private determinePosY(event: Event): number {
        if (!(event instanceof TouchEvent) || event.changedTouches.length === 0) {
            return 0;
        }
        return event.changedTouches[0].clientY ?? 0;
    }
}

type EventInstallable = Window | Document | Element | Element[]

type EventTypeMap<T extends EventInstallable> = T extends Window
    ? WindowEventMap
    : (T extends Document
        ? DocumentEventMap
        : (T extends Element
            ? ElementEventMap
            : (T extends Element[]
                ? ElementEventMap
                : never)));

export class EventInstaller<T extends EventInstallable> {
    private lifetime: Lifetime | undefined;

    public constructor(private element: T) {
    }

    public boundTo(lifetime: Lifetime): this {
        this.lifetime = lifetime;
        return this;
    }

    public on<K extends keyof EventTypeMap<T>>(event: K, listener: (ev: EventTypeMap<T>[K]) => any, options?: AddEventListenerOptions): this;
    public on<K extends keyof CustomEventMap>(event: K, listener: (ev: CustomEventMap[K]) => any, options?: AddEventListenerOptions): this;
    public on(event: string, listener: EventListenerOrEventListenerObject, options?: AddEventListenerOptions): this;
    public on(event: string, listener: EventListenerOrEventListenerObject, options?: AddEventListenerOptions): this {
        const signal = this.lifetime?.signal();
        let effectiveOptions = options;
        if (signal) {
            if (effectiveOptions) {
                effectiveOptions.signal = signal;
            } else {
                effectiveOptions = {signal};
            }
        }
        if (isArray(this.element)) {
            for (const element of (this.element as Element[])) {
                element.addEventListener(event, listener, effectiveOptions);
            }
        } else {
            this.element.addEventListener(event, listener, effectiveOptions);
        }
        return this;
    }
}

export function prepareEvents<T extends Window | Document | Element | Element[]>(element: T): EventInstaller<T> {
    return new EventInstaller(element);
}

