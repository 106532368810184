import {GLOBAL} from "./globals";
import {autoRegister} from "../container";
import {UrlParts} from "./utils/url";

@autoRegister()
export class Nav {

    private location(): Location {
        return GLOBAL.window().location;
    }

    public history(): History {
        return GLOBAL.window().history;
    }

    public isJump(href: string): boolean {
        if (!href.includes("#")) {
            return false;
        }
        if (href.startsWith("#")) {
            return true;
        }

        return UrlParts.from(href).base === UrlParts.from(this.pathname()).base;
    }

    public isRelativeLink(href: string): boolean {
        return href.startsWith("/");
    }

    public hasSameOrigin(href: string): boolean {
        return this.isRelativeLink(href)
            || this.origin() === new URL(href).origin;
    }

    public redirect(href: string): void {
        this.location().href = href;
    }

    public redirectNewWindow(href: string): void {
        GLOBAL.window().open(href, "_blank");
    }

    public pathname(): string {
        return this.location().pathname;
    }

    public getAnchor(): string {
        return this.location().hash;
    }

    public setAnchor(hash: string): void {
        this.location().hash = hash;
    }

    public reload(): void {
        this.location().reload();
    }

    public href(): string {
        return this.location().href;
    }

    public origin(): string {
        return this.location().origin;
    }

    public protocol(): string {
        return this.location().protocol;
    }

    public search(): string {
        return this.location().search;
    }

    public setSearch(search: string): void {
        const updatedHref = replaceSearch(this.href(), search);
        this.history().replaceState(this.history().state, "", updatedHref);
    }

    public base(): string {
        return UrlParts.from(this.href()).base;
    }
}

function replaceSearch(originalUrl: string, search: string): URL {
    const url = new URL(originalUrl);
    url.search = search;
    return url;
}