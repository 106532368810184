import {query, queryAll} from "lit/decorators.js";
import type {PropertyValues} from "lit";
import {html, LitElement, TemplateResult} from "lit";
import {clamp} from "../../../bootstrap/common/numbers";
import {intRangeClosed} from "../../../bootstrap/common/arrays";
import {AutoInitializing} from "../../../common/elements";
import {GLOBAL} from "../../../common/globals";
import {ManagingResources} from "../../../common/lifetime";
import type {Resolution} from "../../../common/resolution";
import {BOOTSTRAP} from "../../../common/resolutionConstants";
import type {Page} from "../../../common/page";
import type {ScrollService} from "../../../common/scroll";


type ExecutiveBoardGalleryConfig = {
    portraitCount: number;
    hasGroupShot: boolean;
    scrollHeight: number;
    portraitOnStageTimeline: number[];
    portraitAnimationStartTimeline: number[];
    portraitTextBoxVisibilityIntervalTimeline: [number, number][];
};

const SCROLL_HEIGHT_PER_ELEMENT = 1000; // adjust to change animation speed

export function executiveBoardGalleryConfig(portraitOnStageTimeline: number[], params: {
    portraitCount: number;
    hasGroupShot: boolean;
}): ExecutiveBoardGalleryConfig {
    // 0.02 is the end of the group shot, if present, or will be ignored otherwise
    // has (calculated) duplicate in scss
    const portraitAnimationStartTimeline = [0.02].concat(portraitOnStageTimeline.slice(0, -1)
        .map(value => value + 0.025));
    const portraitTextBoxVisibilityIntervalTimeline = portraitOnStageTimeline
        .map(value => [value - 0.08, value + 0.04] as [number, number]);
    portraitTextBoxVisibilityIntervalTimeline.last()![1] = 1;
    const imageCount = params.portraitCount + (params.hasGroupShot ? 1 : 0);

    return {
        portraitCount: params.portraitCount,
        hasGroupShot: params.hasGroupShot,
        scrollHeight: imageCount * SCROLL_HEIGHT_PER_ELEMENT,
        portraitOnStageTimeline: portraitOnStageTimeline,
        portraitAnimationStartTimeline: portraitAnimationStartTimeline,
        portraitTextBoxVisibilityIntervalTimeline: portraitTextBoxVisibilityIntervalTimeline
    };
}


export abstract class EopExecutiveBoardGallery extends AutoInitializing(ManagingResources(LitElement)) {

    @query(".content-area")
    private contentArea: HTMLElement;
    @queryAll(".portrait-container")
    private portraitContainers: HTMLElement[];
    @queryAll(".text-box-container")
    private textBoxContainers: HTMLElement[];

    protected constructor(
        private config: ExecutiveBoardGalleryConfig,
        private resolution: Resolution,
        private page: Page,
        private scrollService: ScrollService
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.resolution.onBootstrapBreakpointChange(() => this.requestUpdate(), this);
        GLOBAL.window().addEventListener("scroll", () => this.updateScrollState());

        this.style.setProperty("--desktop-scroll-height", this.config.scrollHeight + "px");
    }

    public render(): TemplateResult {
        if (this.resolution.upTo(BOOTSTRAP.SM)) {
            return this.renderMobile();
        } else {
            return this.renderDesktop();
        }
    }

    private renderDesktop(): TemplateResult {
        this.style.setProperty("--desktop-sticky-distance-to-top",
            -this.page.getViewportOffsetWhenScrolledTo(this.getBoundingClientRect().top) + "px");

        return html`
            <div class="scroll-area">
                <div class="content-area">
                    <div class="intro-text-container">
                        <slot name="intro-headline"></slot>
                        <slot name="intro-text"></slot>
                    </div>
                    ${this.config.hasGroupShot ? html`
                        <div class="group-shot-container">
                            <slot name="group-shot"></slot>
                        </div>
                    ` : ""}
                    ${(this.renderPortraits())}
                    ${(this.renderTextBox())}
                </div>
            </div>
        `;
    }

    private renderPortraits(): TemplateResult[] {
        return intRangeClosed(1, this.config.portraitCount).map(id => this.renderDesktopPortrait(id));
    }

    private renderDesktopPortrait(id: number): TemplateResult {
        const handleScroll = (): Promise<void> => this.scrollToPortrait(id);
        return html`
            <div class="portrait-${id}-container portrait-container"
                 @click=${handleScroll}
                 data-tracking-label="quick-scroll-to-portrait-${id}">
                <slot name="portrait-${id}"></slot>
            </div>
        `;
    }

    private renderTextBox(): TemplateResult[] {
        return intRangeClosed(1, this.config.portraitCount).map(id => this.renderDesktopPortraitTextBox(id));
    }

    private renderDesktopPortraitTextBox(id: number): TemplateResult {
        return html`
            <div class="text-box-${id} text-box-container reverse-background">
                <slot name="text-box-${id}"></slot>
            </div>
        `;
    }

    private renderMobile(): TemplateResult {
        return html`
            <div class="scroll-area">
                <div class="content-area">
                    <div class="intro-headline-container">
                        <slot name="intro-headline"></slot>
                    </div>
                    ${this.config.hasGroupShot ? html`
                        <div class="group-shot-container">
                            <slot name="group-shot"></slot>
                        </div>
                    ` : ""}
                    <div class="intro-text-container">
                        <slot name="intro-text"></slot>
                    </div>
                    <eop-multi-carousel max-elements-per-slide="4">
                        ${(this.renderMobilePortraits())}
                    </eop-multi-carousel>
                </div>
            </div>
        `;
    }

    private renderMobilePortraits(): TemplateResult[] {
        return intRangeClosed(1, this.config.portraitCount).map(id => this.renderMobilePortrait(id));
    }

    private renderMobilePortrait(id: number): TemplateResult {
        return html`
            <div slot="slider-content">
                <div class="portrait-tile reverse-background">
                    <div class="portrait-${id}-container portrait-container">
                        <slot name="portrait-${id}"></slot>
                    </div>
                    <div class="text-box-${id} text-box-container reverse-background">
                        <slot name="text-box-${id}"></slot>
                    </div>
                </div>
            </div>
        `;
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);
        this.updateScrollState();
    }

    private updateScrollState(): void {
        if (this.resolution.upTo(BOOTSTRAP.SM)) {
            return;
        }

        const moduleScrolledRelative = this.moduleScrolledRelative();

        this.style.setProperty("--relative-scroll", "" + moduleScrolledRelative);
        for (let i = 0; i < this.config.portraitCount; i++) {
            if (i > 0 || this.config.hasGroupShot) {
                const isInCarousel = moduleScrolledRelative < this.config.portraitAnimationStartTimeline[i];
                this.portraitContainers[i].classList.toggle("in-carousel", isInCarousel);
            }

            const [start, end] = this.config.portraitTextBoxVisibilityIntervalTimeline[i];
            const isVisible = start <= moduleScrolledRelative && moduleScrolledRelative <= end;
            this.textBoxContainers[i].classList.toggle("visible", isVisible);
        }
    }

    private moduleScrolledRelative(): number {
        const moduleScrolledPixels = -this.getBoundingClientRect().top;
        const moduleScrolledRelative = moduleScrolledPixels / this.scrollInterval();
        return clamp(moduleScrolledRelative, 0, 1);
    }

    private async scrollToPortrait(id: number): Promise<void> {
        const groupShotOffset = this.config.hasGroupShot ? 0 : 1;

        const moduleScrolledRelative = this.config.portraitOnStageTimeline[id - 1 - groupShotOffset];
        const moduleScrolledPixels = moduleScrolledRelative * this.scrollInterval();

        this.classList.add("turbo-scrolling");
        await this.scrollService.scrollToElement(this, -moduleScrolledPixels, 500, 1000);
        this.classList.remove("turbo-scrolling");
    }

    private scrollInterval(): number {
        return this.config.scrollHeight - this.contentArea.clientHeight;
    }
}
