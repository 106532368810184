import {isString} from "../../bootstrap/common/strings";
import {LanguageProfile, LanguagesService} from "../languages";
import {autoRegister, resolve} from "../../container";

@autoRegister()
export class LocatedNumbers {
    private activeLanguage: LanguageProfile;

    public constructor(languageService: LanguagesService = resolve(LanguagesService)) {
        this.activeLanguage = languageService.activeLanguage();
    }

    public parseInteger(value: any): number {
        let valueToParse;

        if (isString(value)) {
            valueToParse = value.replace(new RegExp(`\\${this.activeLanguage.numberGroupSeparator}`, "g"), "");
        } else {
            valueToParse = value;
        }

        return parseInt(valueToParse);
    }

    public formatNumber(value: number, useNumberGroupSeparator: boolean = true): string {
        return this.formatNumberString(value.toString(), useNumberGroupSeparator);
    }

    public formatNumberString(value: string, useNumberGroupSeparator: boolean = true): string {
        const [beforeDecimalSep, afterDecimalSep] = value.split(".");
        const prefix = useNumberGroupSeparator
            ? beforeDecimalSep.replace(/\B(?=(\d{3})+(?!\d))/g, this.activeLanguage.numberGroupSeparator)
            : beforeDecimalSep;
        const suffix = afterDecimalSep === undefined ? "" : `${this.activeLanguage.numberDecimalSeparator}${afterDecimalSep}`;

        return `${prefix}${suffix}`;
    }
}