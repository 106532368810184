import {Load} from "../../common/load";
import {autoRegister, resolve} from "../../container";
import {EventBus} from "../../common/eventBus";
import {GLOBAL} from "../../common/globals";
import {Deferred} from "../../common/utils/promises";
import {MutationObserverFactory} from "../../common/observation";
import {Configuration} from "../../common/config";
import type {PropertyMap} from "../../common/utils/objects";
import {afterContentLoaded} from "../../page/elements/elementFeatures";
import {PageScrollbar} from "../../common/pageScrollbar";
import {Cookies} from "../../common/cookies";
import {ONETRUST_CONSENT_STATUS_COOKIE_NAME, ONETRUST_LAYER_CLOSED_COOKIE_NAME} from "./trackingReset";

export const ONETRUST_CONSENT_CHANGED_EVENT = "onetrust-consent-changed";
export type OneTrustConsentChangedEventData = { marketingAccepted: boolean };
const WEBSITE_CORPORATE_ZONE = "uk";

enum OneTrustCookiePrefs {
    STRICTLY_NECESSARY = "TECH",
    STATISTIC = "STAT",
    MARKETING = "MARK",
    COMFORT = "KOMF",
    SOCIAL_MEDIA = "SOC"
}

type OneTrustObject = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OnConsentChanged: (callback: (event: CustomEvent) => void) => void;
}

export type OneTrustWindow = Window & {
    OneTrust: OneTrustObject;
    OnetrustActiveGroups: string;
    dataLayer: any;
}

@autoRegister()
export class OneTrust {
    private apiLoadedDeferred: Deferred<void>;
    private mutationObserver: MutationObserver;

    private window: OneTrustWindow;

    public constructor(
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private eventBus: EventBus = resolve(EventBus),
        private mutationObserverFactory: MutationObserverFactory = resolve(MutationObserverFactory),
        load: Load = resolve(Load),
        configuration: Configuration = resolve(Configuration),
        cookies: Cookies = resolve(Cookies)
    ) {
        this.window = GLOBAL.window() as OneTrustWindow;
        this.window.dataLayer = this.window.dataLayer ?? [];
        this.window.dataLayer.push({
            zone: WEBSITE_CORPORATE_ZONE
        });
        const ONETRUST_BLOCKED = configuration.get("ONETRUST_BLOCKED") === "true";
        const DOMAIN_ID = configuration.get("ONETRUST_DOMAIN_ID");
        if (!ONETRUST_BLOCKED && DOMAIN_ID) {
            const oneTrustScriptAttributes: PropertyMap<string> = {
                "data-domain-script": DOMAIN_ID,
                "data-document-language": "true",
                charset: "UTF-8"
            };
            void load.script(`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`, oneTrustScriptAttributes);

            const oneTrustLayerFirstOpening = !cookies.exists(ONETRUST_LAYER_CLOSED_COOKIE_NAME) && cookies.exists(ONETRUST_CONSENT_STATUS_COOKIE_NAME);
            if (oneTrustLayerFirstOpening) {
                this.pageScrollbar.disablePageScrollability();
            }
        }

        this.apiLoadedDeferred = new Deferred<void>();
        this.mutationObserver = this.mutationObserverFactory.create(() => this.handleOneTrustLoaded());
    }

    public init(): void {
        this.mutationObserver.observe(GLOBAL.bodyElement(), {childList: true, subtree: true});
    }

    public async isConsentGiven(): Promise<boolean> {
        return this.apiLoadedDeferred.promise.then(() => {
            return this.window.OnetrustActiveGroups ? this.window.OnetrustActiveGroups.includes(OneTrustCookiePrefs.MARKETING) : false;
        });
    }

    private handleOneTrustLoaded(): void {
        this.replaceLogo();
        const oneTrustElement = GLOBAL.bodyElement().querySelector<HTMLElement>("#onetrust-consent-sdk");
        if (oneTrustElement && this.window.OneTrust) {
            oneTrustElement.setAttribute("data-eventelement", "onetrust");
            oneTrustElement.setAttribute("data-default-tracking-label", "onetrust-layer");
            oneTrustElement.classList.add("pagesection");
            this.apiLoadedDeferred.resolve();
            this.mutationObserver.disconnect();
            this.window.OneTrust.OnConsentChanged((event: CustomEvent<OneTrustCookiePrefs[]>) => {
                this.pageScrollbar.enablePageScrollability();
                this.eventBus.dispatchEvent<OneTrustConsentChangedEventData>(ONETRUST_CONSENT_CHANGED_EVENT,
                    {marketingAccepted: event.detail.includes(OneTrustCookiePrefs.MARKETING)});
            });
        }
    }

    private replaceLogo(): void {
        const oneTrustBanner = GLOBAL.bodyElement().querySelector<HTMLElement>("#onetrust-banner-sdk");
        const headerLogo = GLOBAL.bodyElement().querySelector<HTMLElement>("header .logo-box .logo");
        if (oneTrustBanner && headerLogo && this.window.OneTrust) {
            const logoElement = oneTrustBanner.querySelector<HTMLElement>("#onetrust-policy .banner-header")!;
            logoElement.classList.add("logo-box");
            logoElement.replaceChildren(headerLogo.cloneNode(true));
        }
    }
}

afterContentLoaded(() => {
    resolve(OneTrust).init();
});